import React from "react";
import img from "./../../assets/temppic.png";

export default function DrawerImage(props) {
  return (
    <div className={props.className}>
      <img src={img} alt="" className={props.className} />
    </div>
  );
}
