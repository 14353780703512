import React from "react";
import { HiDocumentDownload, HiUser, HiDocumentText } from "react-icons/hi";
import { FaCar } from "react-icons/fa6";
import { BsCurrencyEuro } from "react-icons/bs";
import { BiSolidPen } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logo_cropped.png";
import { FaUser } from "react-icons/fa6";

//Quand on va enlever l'intermédiation la barre du haut va automatiquement s'ajuster pour être droite
export default function UpperBar(props) {
  const steps = [
    { name: "Documents", icon: HiDocumentDownload, url: "/docs" },
    { name: "Conducteur", icon: HiUser, url: "/conducteur" },
    { name: "Véhicule", icon: FaCar, url: "/vehicule" },
    {
      name: "Relevés",
      icon: HiDocumentText,
      url: "/releves",
    },
    { name: "Tarifs", icon: BsCurrencyEuro, url: "/tarifs" },
  ];

  const navigate = useNavigate();

  return (



    <div className="flex justify-between items-center w-full mt-3">
      <div className="w-1/12 pl-4">
        <img src={logo} alt="Logo" className="w-2/3 rounded-full" />
      </div>
      <div className="absolute w-full flex justify-center">
      <div className="flex justify-center items-center text-promo-text w-fit">
        {steps.map((e, i) => {
          const isActive = i === props.step - 1;
          const isCompleted = i < props.step - 1;
          const isFuture = i > props.step - 1;
          const lastStep = i === steps.length - 1;
          
          return (
            <div
            key={i}
            className={`flex items-center justify-center  ${
              lastStep ? "mr-0" : ""
            }`}
            onClick={() => !isFuture && navigate(e.url)}
            style={{ cursor: !isFuture ? "pointer" : "default" }}
            >
              <div className="flex flex-col items-center w-20">
                {isCompleted ? (
                  <AiOutlineCheckCircle size={"50"} className="text-blue-600" />
                ) : (
                  <div
                  className={`p-2 border-2 rounded-full ${
                    isActive
                    ? "border-blue-600 bg-white"
                    : isFuture
                    ? "border-gray-300 bg-gray-100"
                    : "border-blue-600 bg-white"
                  }`}
                  >
                    <e.icon
                      size={"30"}
                      color={isActive || isCompleted ? "#0077B6" : "gray"}
                      fill={isActive || isCompleted ? "#0077B6" : "gray"}
                      />
                  </div>
                )}
                <div className="text-center mt-2">
                  <div
                    className={`text-sm ${
                      isCompleted
                      ? "text-gray-300"
                      : isFuture
                      ? "text-gray-200"
                      : ""
                    }`}
                    >
                  </div>
                  <div
                    className={`text-sm ${
                      isActive
                      ? "font-bold text-black"
                      : isCompleted
                      ? "text-gray-400"
                      : isFuture
                      ? "text-gray-300 opacity-50"
                      : ""
                    }`}
                    >
                    {e.name}
                  </div>
                </div>
              </div>
              {!lastStep && (
                <div
                className={`h-1 w-12 border-t-4 mx-2 rounded-sm mb-5 ${
                  isCompleted ? "border-blue-600" : "border-gray-300"
                }`}
                ></div>
              )}
            </div>
          );
        })}
      </div>
        </div>
      <div className="flex flex-row gap-4">
          <button
              className="flex flex-row bg-promo-green p-3 px-4 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
              onClick={() => {
                navigate('/register')
              }}>
                  Inscription
          </button>
          <button
              className="flex flex-row bg-promo-green p-3 px-4 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
              onClick={() => {
                navigate('/profile')
              }}>
                <FaUser />
          </button>
          <button
        className={`flex flex-row bg-promo-green p-3 px-4 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105`}
        onClick={() => navigate('/intermediation?page=' + props.step)}
      >
        Intermédiation
      </button>
          {props.children}
        </div>
      {/* <div className="w-1/12 flex justify-end pr-4">
              
      </div> */}
    </div>
  );
}
