import React from "react";
import Drawer from "../components/drawer/drawer";
import UpperBar from "../components/upper-bar/upper-bar";
import Content from "../components/tarifs/content";
import packageJson from "../../package.json";
import { useEffect } from 'react';
import { checkLogin } from "../components/utils/check-login";

function Tarifs(props) {
  const version = packageJson.version;
  // useEffect(() => {
  //   checkLogin()
  // })

  return (
    <div className="w-screen h-screen flex flex-col overflow-hidden">
      {/* Drawer en haut */}
      <UpperBar step={"5"} />
      <div className="w-full">
        {" "}
        {/* Ajout de margin-top pour décaler le Drawer vers le bas */}
        <Drawer name="Tarifs"></Drawer>
      </div>
      {/* Contenu principal avec UpperBar à gauche */}
      <div className="flex flex-row flex-grow overflow-hidden">
        {/* UpperBar à gauche */}
        <div className="w-0/6 h-full flex-shrink-0 mt-4">
        </div>
        {/* Contenu principal centré */}
        <div className="flex-grow h-full overflow-y-auto">
          <Content />
        </div>
      </div>
    </div>
  );
}

export default Tarifs;
