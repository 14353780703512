import React from "react";
import Select from "react-select";

function SelectInput(props) {
  return (
    <div className="w-full relative">
      {props.value !== undefined && (
        <div className="bg-white px-1 absolute text-xs text-blue-500 font-bold left-5 bottom-12 z-10">
          {props.label}
        </div>
      )}

      <Select
        options={props.options}
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
        onChange={(selectedOption) => props.onChange(selectedOption)}
        unstyled
        value={
          props.value !== undefined
            ? props.options.filter((option) => option.value === props.value)
            : "undefined"
        }
        classNames={{
          control: () =>
            "border border-gray-300 w-full pt-5 pb-3 px-4 rounded-lg focus:ring-2 focus:ring-blue-500 hover:border-blue-500",
          option: (state) =>
            `bg-white border border-gray-300 py-2 px-4 cursor-pointer hover:bg-gray-100 ${
              state.isSelected
                ? "bg-blue-100"
                : state.isFocused
                ? "bg-gray-100"
                : ""
            } border-b border-gray-200`,
          placeholder: () => "italic text-gray-400", // Ajout du style italic et grisé pour le placeholder
          dropdownIndicator: () => "text-blue-500",
        }}
        label={props.label}
        placeholder={props.placeholder}
        styles={{
          dropdownIndicator: (base, state) => ({
            ...base,
            transition: "transform .2s ease",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
          }),
          menu: (styles) => ({ ...styles, zIndex: 50 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
      />
    </div>
  );
}

export default SelectInput;
