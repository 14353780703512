import React, { useEffect, useState } from "react";
import { HiDocumentText } from "react-icons/hi";
import TextInput from "../utils/text-input";
import DateInput from "../utils/date-input";
import { IoIosAdd } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import {
  sinistresAdded,
  sinistreRemoved,
  sinistreDateChanged,
  sinistreResponsabiliteChanged,
  sinistreNatureChanged,
  bonusReleveAdded,
  echeanceReleveAdded,
  effetReleveAdded,
  resilReleveAdded,
  editionReleveAdded,
  updateMotifResiliation,
  date05Added,
  removeReleve,
  relevesMonths,
  relevesDays
} from "../../root-reducer";
import { BsTrashFill } from "react-icons/bs";
import SelectInput from "../utils/select-input";
import {
  NatureSinistre,
  MotifsResils,
  ResponsabiliteSinistre,
} from "./select-data";

function CardReleve(props) {

  
  const releve = useSelector(
    (state) => state.root.antecedents.releves[props.r]
  );
  const releves = useSelector((state) => state.root.antecedents.releves);
  const dispatch = useDispatch();
  const permis = useSelector((state) => state.root.permis);
  //const [nbMonths, setNbMonths] = useState(0);
  const [bonusMalus, setBonusMalus] = useState(releve.bonus_malus);
  //const [sinistres, setSinistres] = useState([]);
  const tmpbonus = useSelector((state) => state.root);
  
  //useEffect pas nécessaire -> recalcul dans le content.jsx lors de la modif, permet de cleanup le code
  

  const handleBonusMalusChange = (e) => {
    let value = e.target.value;

    //Pour vérifier si c'est format 050, 075, 125 ect... 
    if (/^\d{3}$/.test(value)) {
      value = `${value.slice(0, -2)}.${value.slice(-2)}`;
    } else {
      value = value.replace(',', '.');
    }
    dispatch(bonusReleveAdded({ releve: releve.id, bonus_malus: value }));
    setBonusMalus(value);
  };
  const handleDateChange = (sinistreId, newValue) => {
    const releveId = releve.id
    dispatch(sinistreDateChanged({ sinistreId, newValue, releveId }));
  };

  const handleResponsabiliteChange = (sinistreId, selectedOption) => {
    const releveId = releve.id
    dispatch(
      sinistreResponsabiliteChanged({
        sinistreId,
        responsabilite: selectedOption.value,
        releveId,
      })
    );
  };

  const handleNatureChange = (sinistreId, selectedOption) => {
    const releveId = releve.id
    dispatch(
      sinistreNatureChanged({ sinistreId, nature: selectedOption.value, releveId })
    );
  };

  const handleAddSinistreClick = () => {
    const sinistre_tmp = { 
      sinistre: {
        date: "",
        responsabilite: "",
        nature: "",
      },
      id_releve: releve.id
    }
    dispatch(
      sinistresAdded(sinistre_tmp)
    );
  };
  
  const handleDeleteReleve = (id) => {
    console.log(id)
    dispatch(removeReleve(id))
  }

  return (
<div className="w-full flex flex-row items-center justify-center h-6/6 gap-8 relative">
  <div className="w-[40%] p-2 border-[#F5F7F8] border-[3px] rounded-md flex flex-col gap-4 relative">
  <div className="absolute top-0 left-0 bg-white border border-gray-300 rounded-full w-10 h-10 flex items-center justify-center text-center font-semibold">
  {releve.id + 1}
</div>

    <div
    className="absolute top-0 right-0 bg-white border border-gray-300 rounded-full w-10 h-10 flex items-center justify-center text-center font-semibold cursor-pointer text-red-500"
    onClick={() => handleDeleteReleve(releve.id)}
  >
    X
  </div>
        <div className="w-full bg-[#F5F7F8] rounded-md flex items-center justify-center text-promo-blue p-4">
          <HiDocumentText size={70} />
        </div>
        <div className="w-full flex flex-row gap-4">
        <DateInput
            label={"Date de début de contrat"}
            value={releve.date_effet}
            placeholder="Date de début"
            onChange={(e) => {
              dispatch(effetReleveAdded({ releve: releve.id, date_effet: e }));
            }}
          ></DateInput>
          <DateInput
            label={"Date fin de contrat"}
            value={releve.date_resiliation}
            placeholder="Date de résiliation"
            onChange={(e) => {
              dispatch(
                resilReleveAdded({ releve: releve.id, date_resiliation: e })
              );
            }}
          ></DateInput>
                    <TextInput
            value={releve.months}
            placeholder="Nombre de mois"
            disabled={true}
          ></TextInput>
        </div>
        <div className="w-full flex flex-row gap-4">
          <TextInput
            label={"Bonus/Malus"}
            id="valueBonusFront"
            value={releve && releve.bonus_malus}
            placeholder="Bonus/Malus"
            onChange={handleBonusMalusChange}
            onBlur={() => {
              console.log("ONBLUR")
              if (Number.parseFloat(releve.bonus_malus) < 0.5) {
                setBonusMalus("0.5");
                dispatch(bonusReleveAdded({ releve: releve.id, bonus_malus: "0.50" }));
              } else if (Number.parseFloat(releve.bonus_malus) > 4) {
                dispatch(bonusReleveAdded({ releve: releve.id, bonus_malus: "4" }));
                setBonusMalus("4");
              }
            }}
          ></TextInput>
          <DateInput
            label={"Date d'échéance"} 
            value={releve.date_echeance}
            placeholder="A la date d'échéance du"
            onChange={(e) => {
              dispatch(
                echeanceReleveAdded({ releve: releve.id, date_echeance: e })
              );
            }}
          ></DateInput>
        </div>
        {(bonusMalus.replace(",", ".") === "0.50" || bonusMalus.replace(",", ".") === "0.5" ) && (
          <div className="w-full flex flex-row gap-4">
            <DateInput
              label={"Date du bonus/malus à 0.50"}
              placeholder="Date du bonus/malus à 0.50"
              value={tmpbonus.bonus_05_date.e}
              onChange={(e) => {
                dispatch(date05Added({ e }));
              }}
            ></DateInput>
          </div>
        )}
        <SelectInput
  key={releve.id}
  placeholder="Motif"
  options={MotifsResils} 
  value={releve.motif_resiliation}
  onChange={(e) => {
    let motifValue = e.value;
    let isResilie = true;
    if (motifValue === "" || motifValue === "client") {
      isResilie = false;
    }
    dispatch(
      updateMotifResiliation({ releveId: releve.id, motif: motifValue, resilie: isResilie })
    );
  }}
/>
<div className="h-[5px] bg-gray-300"></div>
      <div className="p-2 border-[#F5F7F8] border-[3px] rounded-md flex flex-col gap-4">
        
        <div className="flex flex-col w-full gap-2">
        {releves[releve.id] && releves[releve.id].sinistres.map((sinistre, index) => (
            <div
              key={index}
              className="flex flex-row items-center justify-center gap-2"
            >
              <DateInput
                value={
                  sinistre.date
                    ? sinistre.date
                    : ""
                }
                placeholder="JJ/MM/AAAA"
                onChange={(newValue) => handleDateChange(index, newValue)}
              />
              <SelectInput
                options={NatureSinistre}
                onChange={(selectedOption) =>
                  handleNatureChange(index, selectedOption)
                }
                value={releves[releve.id].sinistres[index].nature}
                placeholder="Sélectionnez la nature"
              />
              <SelectInput
                options={ResponsabiliteSinistre}
                onChange={(selectedOption) => handleResponsabiliteChange(index, selectedOption)}
                value={
                  sinistre.nature === "bris_de_glace" // Check if the value is "bris_de_glace"
                    ? "0" // Set to "0" (0%) when "nature sinistre" is "bris_de_glace"
                    : releves[releve.id].sinistres[index].responsabilite
                }
                placeholder="Sélectionnez la responsabilité"
                disabled={sinistre.nature === "bris_de_glace"} // Disable when "nature sinistre" is "bris_de_glace"
              />
              <button
                className="w-1/2 text-red-500 cursor-pointer flex items-center justify-center"
                onClick={() =>
                  dispatch(sinistreRemoved({ sinistreId: index, releveId: releve.id }))
                }
              >
                <BsTrashFill size={25} />
              </button>
            </div>
          ))}
        </div>
        <button
          className="w-full border-promo-green border-2 rounded-[4px] text-promo-green text-center text-sm py-1 relative flex flex-row justify-center items-center font-semibold"
          onClick={() =>
            handleAddSinistreClick()
          }
        >
          Ajouter des sinistres
          <div className="absolute right-2">
            <IoIosAdd size={20} />
          </div>
        </button>
      </div>
    </div>
    </div>
  );
}

export default CardReleve;
