import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Zephir from '../components/tarif-compagnies/zephir';
import Drawer from '../components/drawer/drawer';
import DrawerImage from '../components/drawer/drawer-image';
import UpperBar from '../components/upper-bar/upper-bar';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import Oyat from '../components/tarif-compagnies/oyat.jsx';
import packageJson from '../../package.json'; 

function TarifCompagnie(props) {
    const tarif = useSelector(state => state.root.state.tarif)
    const navigate = useNavigate()
    const [content, setContent] = useState(getContent(tarif))
    const version = packageJson.version;
    function getContent(t) {
        switch (t.company) {
            case 'zephir':
                return(<Zephir />)
            case 'oyat':
                return(<Oyat />)
            case 'wazari':
                return(<div>wazari</div>)
            case 'solly':
                return(<div>solly</div>)
            default:
                break
            }
    }

    return (
        <div className='w-screen h-screen flex flex-row'>
        <Drawer step="5" allsteps="6" name="Tarif" description={`Version: ${version}`}> 
            <DrawerImage className="rounded-3xl"></DrawerImage>
        </Drawer>
        <div className='flex flex-col w-[75%] h-full'>
            <UpperBar step={"5"} />
            <div className='h-5/6 flex flex-col items-center relative'>
                <div className=' w-full flex flex-row items-center justify-center absolute bottom-5'>
                    <button className='flex flex-row bg-promo-green w-[25%] py-4 m-4 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green' onClick={() => {navigate('/tarifs')}}>
                        <BsArrowLeftCircleFill size={25} className='absolute left-5'/>
                        <div >ETAPE PRECEDENTE</div>
                    </button>
                    <button className='flex flex-row bg-promo-green w-[25%] py-4 m-4 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green' onClick={() => {navigate('/signature')}}>
                        <div > ETAPE SUIVANTE </div>
                        <BsArrowRightCircleFill size={25} className='absolute right-5'/>
                    </button>
                </div>
                {content}

            </div>
        </div>
    </div> 
    )
}

export default TarifCompagnie;