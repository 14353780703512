import React, { useState, useEffect } from 'react';
import { BiSolidCalendar } from "react-icons/bi";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parse, setDefaultOptions } from 'date-fns';
import { fr } from 'date-fns/locale';
import '../../App.css';
import { useSelector } from "react-redux";

// Définir la locale par défaut sur française
setDefaultOptions({ locale: fr });

function DateInput(props) {
  const permis = useSelector((state) => state.root.permis);
  const { placeholder, label, value, onChange, classname } = props;

  // Convertir value en Date
  const initialDate = value ? parse(value, 'dd/MM/yyyy', new Date()) : null;
  const [startDate, setStartDate] = useState(initialDate);

  useEffect(() => {
    const parsedDate = value ? parse(value, 'dd/MM/yyyy', new Date()) : null;
    if (!isNaN(parsedDate)) {
      setStartDate(parsedDate);
    }
  }, [value]);

  // Condition pour déterminer la classe en fonction de startDate
  const classnamestartDate = startDate ? "pt-5 pb-3" : "py-4";
  //classname = classnamestartDate ? `${classnamestartDate} ${classname}` : classname;


  const handleDateChange = (date) => {
    setStartDate(date);
    if (date) {
      const formattedDate = date.toLocaleDateString('fr-FR');
      onChange(formattedDate);
    } else {
      onChange('');
    }
  };

  const handleRawChange = (event) => {
    const value = event.target.value.replace(/[^\d]/g, ''); // Remove non-numeric characters
    let formattedValue = value;
    if (value.length <= 5) {
      if (value.length > 2) {
        formattedValue = `${value.slice(0, 2)}/${value.slice(2)}`;
      }
      if (value.length > 4) {
        formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`;
      }
      event.target.value = formattedValue;
    }
  };

  return (
    <div className="relative w-full h-full">
      <BiSolidCalendar
        className="absolute h-full right-5 text-promo-green z-10"
        size={25}
      />
      <div
        className="bg-white px-1 absolute text-xs text-blue-500 font-bold left-5 bottom-12 z-10"
        style={{ display: startDate ? "block" : "none" }}
      >
        {label}
      </div>
      <DatePicker
        selected={startDate}
        className={`w-full h-full focus-visible:outline-none border border-gray-300 px-6 ${classname} ${classnamestartDate} placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105 z-20`}
        onChange={handleDateChange}
        onChangeRaw={handleRawChange}
        label={placeholder}
        placeholderText={placeholder}
        showPopperArrow={false}
        locale={fr}
        dateFormat="dd/MM/yyyy"
        wrapperClassName="w-full"
      />
    </div>
  );
}

export default DateInput;
