export const MotifsResils = [
    {value: "", label: "Pas de motif aggravant"},
    {value: "npp", label: "Non paiement"},
    {value: "echeance", label: "Échéance"},
    {value: "sin", label: "Sinistre"},
    {value: "dp", label: "Aggravation risque / pièces manquantes"},
]

export const NatureSinistre = [
    {value: "bris_de_glace", label: "BDG"},
    {value: "materiel", label: "Matériel"},
    {value: "corporel", label: "Corporel"},
    {value: "vol_incendie", label: "Vol / Incendie"},
]

export const ResponsabiliteSinistre = [
    {value: "0", label: "0%"},
    {value: "50", label: "50%"},
    {value: "100", label: "100%"},
]