import React from 'react';
import Drawer from '../components/drawer/drawer';
import UpperBar from '../components/upper-bar/upper-bar';
import DrawerImage from '../components/drawer/drawer-image';
import Content from '../components/signature/content';
import packageJson from '../../package.json'; 

function Documents(props) {
    const version = packageJson.version;
    return ( <div className='w-screen h-screen flex flex-row'>
        <Drawer step="6" allsteps="6" name="Signature" description={`Version: ${version}`}> 
            <DrawerImage className="rounded-3xl"></DrawerImage>
        </Drawer>
        <div className='flex flex-col w-[75%] h-full'>
            <UpperBar step={"6"} />
            <Content></Content>
        </div>
    </div> );
}

export default Documents;