import { useEffect, useState } from "react"
import { FaUser } from "react-icons/fa6"
import { checkLogin } from "../components/utils/check-login"
import AprilLogo  from "../assets/AprilLogo.png"
import WazariLogo  from "../assets/WAZARI-removebg-preview.png"
import LuxiorLogo  from "../assets/LuxiorLogo.png"
import OyatLogo  from "../assets/OyatLogo.png"
import SollyLogo  from "../assets/sollylogo.png"
import ZephirLogo  from "../assets/zephirlogo.png"
import { Dialog, DialogPanel } from '@headlessui/react'
import TextInput  from "../components/utils/text-input"
import { useNavigate } from "react-router-dom"

export default function Profile() {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [companies, setCompanies] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [selected, setSelected] = useState(undefined)
    const [companyEmail, setCompanyEmail] = useState("")
    const [companyPassword, setCompanyPassword] = useState("")
    const navigate = useNavigate()

    function deleteCompany(name) {
        fetch("https://app.promo-assurance.fr/api/auth/remove_company/" + name, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }).then(
            () => {
                fetch("https://app.promo-assurance.fr/api/auth/profile", {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}}).then(res => {
                    res.json().then(e => {
                        setUsername(e.username)
                        setEmail(e.email)
                        setCompanies(e.companies)
                        setIsOpen(false)
                        setCompanyEmail("")
                        setCompanyPassword("")
                    })
                })
            }
        )
    }

    function addCompany(name) {
        fetch("https://app.promo-assurance.fr/api/auth/add_company/" + name, {
            method: 'POST',
            body: JSON.stringify({
                "username": companyEmail,
                "password": companyPassword
            }),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }).then(
            () => {
                fetch("https://app.promo-assurance.fr/api/auth/profile", {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}}).then(res => {
                    res.json().then(e => {
                        setUsername(e.username)
                        setEmail(e.email)
                        setCompanies(e.companies)
                        setIsOpen(false)
                        setCompanyEmail("")
                        setCompanyPassword("")
                    })
                })
            }
        )
    }

    function modCompany(name) {
        fetch("https://app.promo-assurance.fr/api/auth/modify_company/" + name, {
            method: 'POST',
            body: JSON.stringify({
                "username": companyEmail,
                "password": companyPassword
            }),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }).then(
            () => {
                fetch("https://app.promo-assurance.fr/api/auth/profile", {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}}).then(res => {
                    res.json().then(e => {
                        setUsername(e.username)
                        setEmail(e.email)
                        setCompanies(e.companies)
                        setIsOpen(false)
                        setCompanyEmail("")
                        setCompanyPassword("")
                    })
                })
            }
        )
    }

    useEffect(() => {
        checkLogin().then(e => {
            fetch("https://app.promo-assurance.fr/api/auth/profile", {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}}).then(res => {
                res.json().then(e => {
                    setUsername(e.username)
                    setEmail(e.email)
                    setCompanies(e.companies)
                })
            })
        })
    }, [])

    function Card({image, name}) {
        let classname = "w-full border-2 border-solid border-gray-200 rounded-lg flex items-center justify-center h-60 cursor-pointer transition-all duration-300 ease-in-out transform"
        if (!companies.includes(name)) {
            classname += " bg-gray-200 hover:bg-gray-300 hover:border-gray-300"
            console.log(companies, name)
        } else {
            classname += " hover:bg-gray-200"
        }

        return <div className={classname} onClick={() => {setIsOpen(true); setSelected(name)}}>
            <img src={image} alt="" className="w-1/2 h-min object-cover grayscale-50"/>
        </div>
    }

    let classname = ""
    if (isOpen === true)
        classname = " blur-sm grayscale"
    else
        classname = ""
    return <div className={"w-full h-screen flex flex-col justify-around" + classname}>
        <Dialog open={isOpen}  className={"absolute top-0 w-screen h-screen flex items-center justify-center"} onClose={e => {setIsOpen(false); setCompanyEmail(""); setCompanyPassword("")}}>
            <DialogPanel className={"relative bg-white w-1/2 rounded-lg mb-40 flex flex-col p-10 justify-center items-center gap-4 shadow-lg"}>
                <div>{companies.includes(selected) ? "Modifier " + selected : "Ajouter " + selected}</div>
                <div className="w-2/3 flex flex-col items-center justify-center gap-4">
                    <TextInput label="Identifiant" placeholder="Identifiant" onChange={e => setCompanyEmail(e.target.value)} value={companyEmail}/>
                    <TextInput label="Mot de passe" placeholder="Mot de passe" password={true} onChange={e => setCompanyPassword(e.target.value)} value={companyPassword}/>
                </div>

                <div className="flex flex-row w-full justify-center gap-4">

                <button
                    className="flex flex-row bg-promo-green w-1/3 py-3 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
                    onClick={() => {
                        if (companies.includes(selected)) {
                            modCompany(selected)
                        } else {
                            addCompany(selected)
                        }
                    }}>
                        {companies.includes(selected) ?
                        "Appliquer les changements" : "Ajouter " + selected
                    }
                </button>
                {
                    companies.includes(selected) ? 
                    <button className=" flex flex-row bg-red-600 w-1/3 py-3 text-white items-center justify-center font-semibold hover:bg-red-800 rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
                        onClick={() => {deleteCompany(selected)}}
                    >
                        Supprimer {selected}
                    </button> :
                    <></>
                }
                </div>

            </DialogPanel>
        </Dialog>
        <div className="w-full flex flex-col items-center justify-center">
            <div className="rounded-full overflow-hidden pt-8 px-4 bg-gray-200 mb-4">
                <FaUser size={100} className="text-promo-green"/>
            </div>
            <div className="flex flex-row gap-4">
                <TextInput value={username} onChange={() => {}} label="Nom d'utilisateur" disabled/>
                <TextInput value={email} onChange={() => {}} label="Adresse email" disabled/>
                <button onClick={() => {
                    navigate('/mod-password')
                }}
                className="bg-promo-green whitespace-nowrap p-3 text-white items-center justify-center font-semibold hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 top-5 left-5">Modifier le mot de passe</button>
            </div>
        </div>
        <div className="w-full flex flex-col gap-6 mb-4">
            <div className="w-full flex flex-row gap-6 px-40 ">
                <Card image={OyatLogo} name="OYAT"/>
                <Card image={ZephirLogo} name="ZEPHIR"/>
                <Card image={WazariLogo} name="WAZARI"/>
            </div>
            <div className="w-full flex flex-row gap-6 px-40">
                <Card image={SollyLogo} name="SOLLY"/>
                <Card image={AprilLogo} name="APRIL"/>
                <Card image={LuxiorLogo} name="LUXIOR"/>
            </div>
        </div>
        <button
            className="absolute bg-promo-green w-1/6 py-3 text-white items-center justify-center font-semibold hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 top-5 left-5"
            onClick={() => {
                navigate('/docs')
                }}>
            {"<-"} Retour au menu
        </button>
    </div>
}