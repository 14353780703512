import React, { useState, useEffect } from 'react';
import TextInput from '../utils/text-input';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { recoAdded, notesAdded, besoinsAdded, montantAdded, formuleAdded } from '../../root-reducer';
import TextAreaInput from '../utils/textarea-input';
import SelectInput from '../utils/select-input';

function Content(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let sign = useSelector(state => state.root.signature)

    const adresse1 = useSelector(state => state.root.antecedents.global.adresse);
    const [redirected, setRedirected] = useState(false);

    useEffect(() => {
        if (!adresse1.numero && !redirected) {
            window.location.href = "/docs";
            setRedirected(true);
        }
    }, [adresse1.numero, redirected]);


    return ( 
        <div className='h-5/6 flex flex-col items-center relative'>
            <div className=' w-full flex flex-row items-center justify-center absolute bottom-5'>
                <button className='flex flex-row bg-promo-green w-[25%] py-4 m-4 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green' onClick={() => {navigate('/tarif-compagnie')}}>
                    <BsArrowLeftCircleFill size={25} className='absolute left-5'/>
                    <div >ETAPE PRECEDENTE</div>
                </button>
                <button className='flex flex-row bg-promo-green w-[25%] py-4 m-4 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green' onClick={() => {navigate('/signature')}}>
                    <div > SIGNER </div>
                    <BsArrowRightCircleFill size={25} className='absolute right-5'/>
                </button>
            </div>

            <div className='w-[60%] flex flex-col gap-4 my-8 h-5/6'>
                <TextAreaInput placeholder="Notes" onChange={(e) => {dispatch(notesAdded(e.target.value))}} value={sign.notes}/>
                <TextAreaInput placeholder="Besoins" onChange={(e) => {dispatch(besoinsAdded(e.target.value))}} value={sign.besoins}/>
                <TextInput placeholder="Montant" onChange={(e) => {dispatch(montantAdded(e.target.value))}} value={sign.montant}/>
                <SelectInput  options={[{label: "Oui", value: true}, {label: "Non", value: false}]} placeholder="Le client suit-il les recommendations ?" onChange={(e) => {dispatch(recoAdded(e.value))}}/>
                <SelectInput  options={[{label: "formule 1", value: "1"}, {label: "formule 2", value: "formule 2"}]} placeholder="Formule" onChange={(e) => {dispatch(formuleAdded(e.value))}}/>
            </div>
        </div>
    );
}

export default Content;