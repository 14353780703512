function compareDates(a, b) {
  function createDateFromFormat(dateString) {
    const parts = dateString.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are zero-based
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  const date1 = createDateFromFormat(a.date_resiliation);
  const date2 = createDateFromFormat(b.date_resiliation);
  if (a.date_resiliation === "" || !a.date_resiliation) {
    return a;
  }
  if (date1 < date2) {
    return b;
  } else if (date1 >= date2) {
    return a;
  }
}

function findLast(releves) {
  console.log("Un relevé trouvé");
  return releves.reduce((acc, cur) => compareDates(acc, cur));
}

function toDate(d) {
  const a = d.split("/");
  console.log(a);
  return new Date(a[2], a[1] - 1, a[0]);
}

function getSinistresApresEcheance(releve) {
  const v = releve.sinistres.filter((sinistre) => {
    return toDate(sinistre.date) >= toDate(releve.date_echeance);
  });
  console.log(v);
  return v;
}

function plafondBonus(bonus) {
  if (bonus < 0.5) return 0.5;
  if (bonus > 3.5) return 3.5;
  return bonus;
}

function calcSinistres(bonus, sinistres) {
  sinistres.forEach((sin) => {
    console.log(sin);
    if (sin.responsabilite === "100") {
      bonus = plafondBonus(Math.floor(bonus * 1.25 * 100) / 100);
    } else if (sin.responsabilite === "50") {
      bonus = plafondBonus(Math.floor(bonus * 1.125 * 100) / 100);
    }
  });
  return bonus;
}

function addMonths(date, months) {
  console.log("BYEBYEBYE", date)
  if (!(date instanceof Date)) {
    console.error("date must be a Date object");
    return;
  }

  date.setMonth(date.getMonth() + months);
  return date;
}

function toD(str) {
  function swap(arr) {
    const temp = arr[0];
    arr[0] = arr[1];
    arr[1] = temp;
    return arr;
  }
    let dateString;
    console.log('Fonction toD : '+ str)
    if (!str) {
      // If str is empty, return the Date 01-01-1970
      return new Date('01-01-1970');
    }
    else if (typeof str === 'string' && str.includes('/')) {
        // If the input is a string and contains '/', it's assumed to be in the format 'dd/mm/yyyy'
        dateString = str.split('/').reverse().join('-');
    } else if (Array.isArray(str) && str.length === 3) {
        // If the input is an array with three elements, it's assumed to be [yyyy, mm, dd]
        dateString = `${str[0]}-${str[1]}-${str[2]}`;
    } else {
        throw new Error('Invalid input format. Input must be a string in "dd/mm/yyyy" format or an array of [dd, mm, yyyy].');
    }
    return new Date(dateString);
  //return new Date(swap(str.split("/")).join("-"));
}

function calc5PcBonus(bonus, releve, sinistres, date_effet) {
  //Il y a un sinistre donc retourne le calcul précédent du sinistre
  if (sinistres.length !== 0) {
    return bonus;
  }
  console.log("Nouveau", toD(releve.date_resiliation))
  console.log(toD(date_effet))
  console.log(date_effet)
  //Si il n'y a pas de date de résiliation ou que la date de résiliation est après la date d'effet
  if (releve.date_resiliation === "" || !releve.date_resiliation || toD(releve.date_resiliation) > toD(date_effet)) {
    //On compare la date de début du contrat voir si il y a bien 9 mois entre le nouveau contrat et l'ancien pour évoluer le bonus
    if (toD(date_effet) >= addMonths(toD(releve.date_echeance), 9)) {
      return plafondBonus(bonus * 0.95);
    }
    return plafondBonus(bonus);
  }
  let dateEffet = toD(releve.date_effet);
    if(toD(releve.date_echeance) > dateEffet)
      dateEffet = toD(releve.date_echeance)
  let dateResiliation = toD(releve.date_resiliation);
  let diffMois = (dateResiliation.getFullYear() - dateEffet.getFullYear()) * 12 + (dateResiliation.getMonth() - dateEffet.getMonth());
  
  console.log(diffMois)
  // Vérifier si la différence est d'au moins 9 mois
  if (diffMois >= 9) {
    return plafondBonus(bonus * 0.95);
  } else {
    return plafondBonus(bonus);
  }
}

export function CalcBonus(releves, date_effet) {
  console.log("releve dans le js", releves)
  console.log("Length", releves.length)
  const last = releves[releves.length - 1]
  console.log("resume releve", last);
  //ON trouve pas de relevé donc ereturn 1 de value pour le bonus 
  if (last == undefined)
    return 1
  let v;
  try {
    let a = last.bonus_malus;
    v = parseFloat(a.replace(",", "."));
  } catch (error) {
    v = 1.0;
  }
  
  let bonus = calcSinistres(v, getSinistresApresEcheance(last));
  bonus = plafondBonus(
    calc5PcBonus(bonus, last, getSinistresApresEcheance(last), date_effet)
  );
  return bonus;
}
