import React, { useState, useEffect } from "react";
import CardReleve from "./card-releve";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import CardResume from "./card-resume";
import {
  differenceInMonths,
  differenceInDays,
  parseISO,
  addMonths,
  max,
  min,
  format,
  subYears,
  addDays,
} from "date-fns";
import {
  ancienneteGlobalAdded,
  bonusGlobalAdded,
  addReleveEmpty,
  relevesDays,
  relevesMonths,
  updateMotifResiliation,
} from "../../root-reducer";
import { CalcBonus } from "./bonus";

function Content(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const r = useSelector((state) => state.root.antecedents.releves);
  const permis = useSelector((state) => state.root.permis);
  const tmpbonus = useSelector((state) => state.root);

  const [active, setActive] = useState(0);


  function checkValidate() {
    const a = r.map((rel) => {
      if (rel.date_effet.length !== 10 || rel.bonus_malus === "" || rel.date_echeance.length !== 10 ) {
        console.log("first")
        return false
      }
      if (rel.motif_resiliation !== "" && rel.date_resiliation.length !== 10) {
        console.log("second")
        return false
      }
      console.log("three")
      if ((rel.bonus_malus === "0.5" || rel.bonus_malus === "0.50") && tmpbonus.bonus_05_date.e.length !== 10)
        return false
      return true
    })
    console.log(!a.includes(false))
    return !a.includes(false)
  }

  useEffect(() => {
    const today = format(new Date(), "dd/MM/yyyy");
    const bonus = CalcBonus(r, permis.date_deffet);
  
    // Limite de 36 mois
    const cutoffDate = subYears(parseISO(convertDate(permis.date_deffet)), 3);

    // Pour l'overlap des mois/jours
    const countedMonths = new Set();
    const countedDays = new Set();
    
    for (let index = r.length - 1; index >= 0; index--) {
      const entry = r[index];
      if (entry) {
        const dateResil = entry.date_resiliation 
      ? parseISO(convertDate(entry.date_resiliation)) 
      : parseISO(convertDate(permis.date_deffet));
        let dateDeffet = parseISO(convertDate(entry.date_effet));
    
        // Motif filtering logic
        if (
          entry.motif_resiliation !== "" &&
          entry.motif_resiliation !== "npp" &&
          entry.motif_resiliation !== "echeance" &&
          entry.motif_resiliation !== "sin" &&
          entry.motif_resiliation !== "dp"
        ) {
          console.log("CHANGEMENT MOTIF" + index, false, "");
          console.log("Ancien motif" + entry.motif_resiliation);
          dispatch(
            updateMotifResiliation({ releveId: index, motif: "", resilie: false })
          );
        }
    
        // Si la date d'effet du relevé était y'a trop longtemps on setup a il y a 36 mois
        if (dateDeffet < cutoffDate) {
          dateDeffet = cutoffDate;
        }
    
        // calcul, self explanatory
        const monthsBetween = differenceInMonths(dateResil, dateDeffet);
        const endOfFullMonths = addMonths(dateDeffet, monthsBetween);
        const leftoverDays = differenceInDays(dateResil, endOfFullMonths);
    
        // Pour vérifier les mois unique et pas avoir d'overlap
        let uniqueMonthsCount = 0;
        for (let i = 0; i < monthsBetween; i++) {
          const monthToCheck = addMonths(dateDeffet, i).toISOString().slice(0, 7); // Format as YYYY-MM
          if (!countedMonths.has(monthToCheck)) {
            countedMonths.add(monthToCheck);
            uniqueMonthsCount++;
          }
        }
    
        // Même chose pour les jours
        let uniqueDaysCount = 0;
        for (let i = 0; i < leftoverDays; i++) {
          const dayToCheck = addDays(endOfFullMonths, i).toISOString().slice(0, 10); // Format as YYYY-MM-DD
          if (!countedDays.has(dayToCheck)) {
            countedDays.add(dayToCheck);
            uniqueDaysCount++;
          }
        }
    
        console.log("Unique Days: " + uniqueDaysCount);
        console.log("Unique Months: " + uniqueMonthsCount);
    
        // Dispatch results
        dispatch(relevesDays({ id: index, days: uniqueDaysCount }));
        dispatch(relevesMonths({ id: index, months: uniqueMonthsCount }));
      }
    }
      
    let dureeEnMois = 0;
    let tmp_value_days = 0

    //On calcul avec tout les relevés le nombre total
    r.forEach((entry) => {
      if (entry) {
        dureeEnMois += entry.months || 0;
        dureeEnMois += (entry.days || 0) / 31; // On prends 31j pour estimer un mois
        tmp_value_days += entry.days;
      }
    });
    
    //Si il y a moins de 31 jours avec les leftovers, on regarde si un des relevés a au moins 16jours pour lui offrir un mois
    //Logiquement un seul possible 
    if (tmp_value_days < 31) {
      r.forEach((entry) => {
        if (entry) {
          if (entry.days >= 16) {
            dureeEnMois += 1
          }
        }
      });
    }
  
    const flooredValue = Math.floor(dureeEnMois);
    dispatch(ancienneteGlobalAdded(flooredValue > 36 ? 36 : flooredValue));
    dispatch(bonusGlobalAdded(Math.floor(bonus * 100) / 100));
  }, [r, permis.date_deffet, dispatch]);
  

  const convertDate = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="h-5/6 flex flex-col items-center relative">
                  <div className="text-2xl font-semibold text-promo-text my-6" style={{ visibility: "hidden" }}>
        Importez vos documents ou passez directement à l'étape suivante
      </div>
      {r[active] ? <CardReleve r={active} /> : <CardResume />}
      
      <div className="flex flex-col items-center justify-between gap-4 mt-10">
        <div className="flex flex-row items-center justify-between gap-4">
          {r.map((e, index) => (
            <button
              key={index}
              className="text-white px-4 py-2 bg-promo-grey rounded-lg hover:bg-blend-darken"
              style={active === e.id ? { backgroundColor: "#0077B6" } : {}}
              onClick={() => setActive(e.id)}
            >
              Relevé {e.id + 1}
            </button>
          ))}
          <button
            className="text-white px-4 py-2 bg-promo-grey rounded-lg hover:bg-blend-darken"
            style={active === r.length ? { backgroundColor: "#0077B6" } : {}}
            onClick={() => setActive(r.length)}
          >
            Résumé
          </button>
        </div>
        <button
          className="text-white px-4 py-2 bg-promo-blue rounded-lg hover:bg-blend-darken mt-4"
          onClick={() => {
            const newReleves = {
              bonus_malus: '',
              date_echeance: '',
              date_edition: '',
              date_effet: '',
              date_resiliation: '',
              id: r.length,
              motif_resiliation: '',
              resilie: false,
              sinistres: [],
              months: 0,
              days: 0,
            };
            dispatch(addReleveEmpty(newReleves));
          }}
        >
          Ajouter un relevé
        </button>
      </div>
      
      <div className="flex flex-row w-full justify-center fixed bottom-4 left-0 right-0">
  <button
    className="flex flex-row bg-promo-green w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
    onClick={() => navigate("/vehicule")}
  >
    <BsArrowLeftCircleFill size={25} className="absolute left-5" />
    <div>ETAPE PRECEDENTE</div>
  </button>

  {
    checkValidate() ?   <button
    className="flex flex-row bg-promo-green w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
    onClick={() => navigate("/tarifs")}
  >
    <div>ETAPE SUIVANTE</div>
    <BsArrowRightCircleFill size={25} className="absolute right-5" />
  </button> :         <button
        className="flex flex-row bg-gray-500 w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative rounded-full shadow-lg transition-all duration-300 ease-in-out transform"
        onClick={() => {
        }}
      >
        <div>ETAPE SUIVANTE</div>
        <BsArrowRightCircleFill size={25} className="absolute right-5" />
      </button>
  }

</div>

    </div>
  )};  
export default Content;