import React, { useState, useEffect } from "react";
import TextInput from "../utils/text-input";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import DateInput from "../utils/date-input";
import { addDays } from 'date-fns';
import { useDispatch, useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {
  immatriculationAdded,
  achatAdded,
  chevauxAdded,
  birthdayCarAdded,
  vehiculeAssureAdded,
  dateResilAdded,
  trackerAdded,
  financementAdded,
} from "../../root-reducer";
import SelectInput from "../utils/select-input";
import toast from "react-hot-toast";

function Content(props) {
  const navigate = useNavigate();
  const carte_grise = useSelector((state) => state.root.carte_grise);
  const dispatch = useDispatch();
  const [estAssure, setEstAssure] = useState(false);
  const [dateResil, setDateResil] = useState("");

  function validateForm() {
    if (carte_grise.immatriculation.length < 9 || carte_grise.purchase_date === "" || carte_grise.tax_horsepower === "" || carte_grise.birthday_car === "" || carte_grise.vehicule_assure === undefined) {
      return false
    }
    if (carte_grise.vehicule_assure === false && carte_grise.date_resil.length < 10) {
      return false
    }
    return true

  }

  useEffect(() => {
    if (carte_grise.vehicule_assure !== undefined) {
      setEstAssure(carte_grise.vehicule_assure);
    }
    if (carte_grise.date_resil !== "") {
      setDateResil(carte_grise.date_resil);
    }
  }, [carte_grise]);

  function compareDate(date1, date2) {
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    return (
      new Date(date1.replace(pattern, "$3-$2-$1")) >=
      new Date(date2.replace(pattern, "$3-$2-$1"))
    );
  }

  function handleNext() {
    const immatRegex = /[A-Za-z][A-Za-z]-\d\d\d-[A-Za-z][A-Za-z]/i;
    
    if (
      carte_grise.immatriculation === "" ||
      immatRegex.test(carte_grise.immatriculation) === false
    ) {
      console.log("Error")
      toast.error("L'immatriculation doit etre au format XX-000-XX");
    } else if (carte_grise.purchase_date === "") {
      toast.error("Il manque la date d'achat");
    } else if (carte_grise.tax_horsepower === "") {
      toast.error("Il manque les chevaux fiscaux");
    } else if (carte_grise.birthday_car === "") {
      toast.error("Il manque la date de premiere immat");
    } else if (carte_grise.vehicule_assure === undefined) {
      toast.error("Le vehicule est-il assure actuellement ?");
    } else if (compareDate(carte_grise.purchase_date, carte_grise.birthday_car) === false) {
      toast.error("La date d'achat du véhicule doit être supérieure ou égale à la date de première immatriculation");
    } else {
      navigate("/releves");
    }
  }

  return (
    <div className=" h-5/6 flex flex-col items-center justify-center relative">
                  <div className="text-2xl font-semibold text-promo-text my-6" style={{ visibility: "hidden" }}>
        Importez vos documents ou passez directement à l'étape suivante
      </div>
      <div className="w-[40%] flex flex-col gap-4 my-8 h-5/6">
        <div className="flex flex-row gap-8 w-full justify-between">
        <TextInput
            placeholder={"Immatriculation"}
            classname={`border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105`}
            onChange={(e) => {
              let val = e.target.value.split("-").join('')
              val = val.split(/(?<=\D)(?=\d)|(?<=\d)(?=\D)/).join('-');
              dispatch(immatriculationAdded(val));
            }}
            value={carte_grise.immatriculation}
          ></TextInput>
          <DateInput
            placeholder={"Date d'achat du véhicule"}
            label={"Achat du véhicule"}
            value={carte_grise.purchase_date}
            onChange={(e) => dispatch(achatAdded(e))}
          ></DateInput>
        </div>
        <div className="flex flex-row gap-8 w-full justify-between">
          <TextInput
            placeholder={"Nombre de chevaux fiscaux"}
            label={"Chevaux fiscaux"}
            classname={`border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105`}
            onChange={(e) => dispatch(chevauxAdded(e.target.value))}
            value={carte_grise.tax_horsepower}
            maxLength={2}
          ></TextInput>
          <DateInput
            placeholder={"Date de la premiere immatriculation"}
            label={"Premiere immatriculation"}
            value={carte_grise.birthday_car}
            onChange={(e) => dispatch(birthdayCarAdded(e))}
          ></DateInput>
        </div>
        <div className="flex gap-8 w-full">
        {/* Question on the left */}
        <div className="w-1/2 flex items-center justify-end">
          <label className="font-medium">Véhicule assuré actuellement ?</label>
        </div>
        {/* Options on the right */}
        <div className="w-1/2 flex flex-col">
          <div className="flex gap-4 items-center">
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="assure"
                value="yes"
                checked={estAssure === true}
                onChange={() => {
                  setEstAssure(true);
                  dispatch(vehiculeAssureAdded(true));
                }}
              />
              Oui
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="assure"
                value="no"
                checked={estAssure === false}
                onChange={() => {
                  setEstAssure(false);
                  dispatch(vehiculeAssureAdded(false));
                }}
              />
              Non
            </label>
          </div>
          </div>
        </div>
        <div className="flex gap-4 w-full">
        <div className="w-1/2"></div>
                            {/* Conditionally render the "Depuis quand?" input */}
                            {estAssure === false && (
            <div className="mt-4">
              <DateInput
                placeholder={"Depuis quand ?"}
                label={"Depuis quand ?"}
                onChange={(e) => dispatch(dateResilAdded(e))}
                value={carte_grise.date_resil}
              />
            </div>
          )}
          </div>
      </div>
      <div className="flex flex-row w-full justify-center fixed bottom-4 left-0 right-0">
  <button
    className="flex flex-row bg-promo-green w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
    onClick={() => navigate("/conducteur")}
  >
    <BsArrowLeftCircleFill size={25} className="absolute left-5" />
    <div>ETAPE PRECEDENTE</div>
  </button>
  {
    validateForm() ? (  <button
      className="flex flex-row bg-promo-green w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
      onClick={() => handleNext()}
    >
      <div>ETAPE SUIVANTE</div>
      <BsArrowRightCircleFill size={25} className="absolute right-5" />
    </button>) : (
        <button
        className="flex flex-row bg-gray-500 w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative rounded-full shadow-lg transition-all duration-300 ease-in-out transform"
        onClick={() => {
          handleNext();
        }}
      >
        <div>ETAPE SUIVANTE</div>
        <BsArrowRightCircleFill size={25} className="absolute right-5" />
      </button>
    )
  }

</div>


    </div>
  );
}

export default Content;
