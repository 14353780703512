import React from "react";
import Drawer from "../components/drawer/drawer";
import DrawerImage from "../components/drawer/drawer-image";
import CarteGrise from "../components/vehicule/carte-grise";
import UpperBar from "../components/upper-bar/upper-bar";
import Content from "../components/vehicule/content";
import packageJson from "../../package.json";

export default function Vehicule(props) {
  const version = packageJson.version;
  return (
    <div className="w-screen h-screen flex flex-col overflow-hidden">
      {/* Drawer en haut */}
      <UpperBar step={"3"} />
      <div className="w-full">
        {" "}
        {/* Ajout de margin-top pour décaler le Drawer vers le bas */}
        <Drawer name="Véhicule">{/*<CarteGrise />*/}</Drawer>
      </div>
      {/* Contenu principal avec UpperBar à gauche */}
      <div className="flex flex-row flex-grow overflow-hidden">
        {/* UpperBar à gauche */}
        <div className="w-0/6 h-full flex-shrink-0 mt-4">
        </div>
        {/* Contenu principal centré */}
        <div className="flex-grow h-full overflow-y-auto">
          <Content />
        </div>
      </div>
    </div>
  );
}
