import React, { useState, useEffect } from 'react';

function AppLogs() {
    const [logs, setLogs] = useState('');

    useEffect(() => {
        fetch('https://app.promo-assurance.fr/logs')
            .then(response => response.text())  // Traitement de la réponse comme texte brut
            .then(data => setLogs(data))
        .catch(error => console.error('Error fetching logs:', error));
    }, []);

    return (
         <div style={{
            bottom: 0,
            left: 0,
            right: 0,
            maxHeight: '900px',
            overflow: 'auto',
            backgroundColor: 'black',  // Fond noir
            color: 'white',  // Texte en blanc
            padding: '10px',
            boxSizing: 'border-box',
			overflow: 'auto',  // Active les barres de défilement lorsque nécessaire
            boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',  // Optionnel: ajoute une ombre pour mieux voir la box
            fontFamily: 'monospace',  // Utilisation d'une police à espacement fixe pour une meilleure lisibilité des logs
            wordWrap: 'break-word',  // Assure le retour à la ligne automatique des mots longs
            whiteSpace: 'pre-wrap'  // Conserve les espaces naturels et les sauts de ligne mais ajoute un retour à la ligne automatique
        }}>
            <pre><code>{logs}</code></pre>
        </div>
    );
}

export default AppLogs;
