import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Documents from './pages/documents';
import Conducteur from './pages/conducteur';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './root-reducer';
import { Provider } from 'react-redux';
import Vehicule from './pages/vehicule';
import Releves from './pages/releves';
import Tarifs from './pages/tarifs';
import Signature from './pages/signature';
import TarifCompagnie from './pages/tarif-compagnie';
import Hubspot from './pages/hubspot';
import Intermediation from './pages/intermediation';
import AppLogs from './pages/logs';
import {Provider as AlertProvider} from 'react-alert'
import AlertTemplate from 'react-alert-template-basic';
import { Toaster } from 'react-hot-toast';
import Login from './pages/login';
import Register from './pages/register';
import Profile from './pages/profile';
import ModPassword from './pages/mod-password';

export const store = configureStore({
  reducer: {
    root: rootReducer,
  }
})

function App() {
  return (
    <div>
      <AlertProvider template={AlertTemplate}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Navigate to={"/docs"}/>}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/mod-password" element={<ModPassword />}></Route>
              <Route path="/register" element={<Register />}></Route>
              <Route path="/profile" element={<Profile />}></Route>
              <Route path="/docs" element={<Documents />}></Route>
              <Route path="/hubspot" element={<Hubspot />}></Route>
              <Route path="/intermediation" element={<Intermediation />}></Route>
              <Route path="/conducteur" element={<Conducteur />}></Route>
              <Route path="/vehicule" element={<Vehicule />}></Route>
              <Route path="/releves" element={<Releves />}></Route>
              <Route path="/tarifs" element={<Tarifs />}></Route>
              <Route path="/tarif-compagnie" element={<TarifCompagnie />}></Route>
              <Route path="/signature" element={<Signature />}></Route>
              <Route path="/logs" element={<AppLogs />}></Route>
            </Routes>
          </BrowserRouter>
          <Toaster position="top-right" reverseOrder={false}/>
        </Provider>
      </AlertProvider>
    </div>
  );
}

export default App;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);