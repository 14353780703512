import React from 'react';
import FileUploader from '../utils/file-uploader';
import TextInput from '../utils/text-input';
import zephirLogo from '../../assets/zephirlogo.png'

function Zephir(props) {
    return ( 
        <div className='w-[60%] h-5/6 flex flex-col items-center py-8  '>
            <div className='h-full w-full flex flex-row items-center justify-between gap-4'>
                <img className='w-[30%]' src={zephirLogo} alt="" />
                <div className='w-[65%] h-full flex flex-col justify-evenly'>
                    <TextInput disabled={true} placeholder={"Formule"}/>
                    <TextInput disabled={true} placeholder={"Tarif"}/>
                    <TextInput disabled={true} placeholder={"Assistance"}/>

                </div>
            </div>
            <div className='flex flex-row w-full items-start justify-between'>
                <FileUploader text={"RIB"}/>
                <div className='w-[65%] h-full flex flex-col justify-around'>
                    <TextInput placeholder="IBAN" onChange={(e) => {console.log(e)}}/>
                    <TextInput placeholder="Code BIC" onChange={(e) => {console.log(e)}}/>
                    <TextInput placeholder="Nom de la banque" onChange={(e) => {console.log(e)}}/>
                </div>
            </div>
        </div>
    );
}

export default Zephir;