import React, { useState } from 'react';
import TextInput from '../utils/text-input';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    bonusGlobalAdded,
    dateEffetAdded,
    addSinistres,
    nameAdded,
    firstNameAdded,
    birthdateAdded,
    licenseDateAdded,
    telAdded,
    emailAdded,
    adresseNumeroAdded,
    adresseRueAdded,
    adresseCodePostalAdded,
    adresseVilleAdded,
    immatriculationAdded,
    achatAdded,
    chevauxAdded,
    birthdayCarAdded,
    dateResilAdded,
    financementAdded,
    adresseComplementAdded,
  } from "../../root-reducer";

function Content(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`;
    const [familyName, setFamilyName] = useState('');
    const [response, setResponse] = useState('');

    const handleSearch = async () => {
        try {
            const response = await fetch('https://app.promo-assurance.fr/api/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ familyName })
            });

            const data = await response.json();
            if (response.ok) {
                setResponse(data.results[0].properties);
            } else {
                console.error('Error fetching family name:', data.error);
                setResponse('');
            }
        } catch (error) {
            console.error('Error fetching family name:', error);
        }
    };

    const handleRedirect = () => {
        dispatch(nameAdded(response.lastname));
        dispatch(firstNameAdded(response.firstname));
        dispatch(telAdded(response.phone));
        dispatch(emailAdded(response.email));
        dispatch(adresseRueAdded(response.address));
        dispatch(adresseCodePostalAdded(response.zip));
        dispatch(adresseVilleAdded(response.city));
        dispatch(birthdateAdded(""));
        dispatch(licenseDateAdded(""));
        dispatch(
            adresseNumeroAdded(
              ""
            )
          );
        dispatch(
            adresseComplementAdded(
              ""
            )
          );
          dispatch(
            immatriculationAdded("")
          );
          dispatch(achatAdded(""));
          dispatch(chevauxAdded(""));
          dispatch(
            birthdayCarAdded("")
          );
          dispatch(dateResilAdded(""));
          dispatch(financementAdded(""));
          dispatch(
            bonusGlobalAdded("")
          );
          dispatch(
            addSinistres([])
          );
          dispatch(dateEffetAdded(formattedDate));
          dispatch(financementAdded("comptant"));
        navigate('/conducteur');
    };

    return (
        <div className="h-5/6 flex flex-col items-center relative p-4">
            <div className="w-full flex flex-col items-center justify-center mt-12">
                <div className="w-2/3 sm:w-1/2 lg:w-1/3 flex flex-row items-center justify-center mb-4">
                    <TextInput
                        placeholder="Nom de famille"
                        value={familyName}
                        onChange={(e) => setFamilyName(e.target.value)}
                        className="flex-grow p-2 border border-gray-300 rounded-md"
                    />
                    <button onClick={handleSearch} className="ml-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
                        Chercher
                    </button>
                </div>
                <div className="w-full flex flex-col items-center justify-center">
                    {response ? (
                        <div className="text-gray-500">
                            <p>Prénom: {response.firstname}</p>
                            <p>Nom de famille: {response.lastname}</p>
                            <p>Email: {response.email}</p>
                            <p>Téléphone: {response.phone}</p>
                            <p>Adresse: {response.address}</p>
                            <p>{response.city}</p>
                            <p>{response.zip}</p>
                            <p>{response.state}</p>
                            <button onClick={handleRedirect} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-green-600">
                                Continuer
                            </button>
                        </div>
                    ) : (
                        <p className="text-gray-500">Aucune réponse.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Content;
