import { useState } from "react"
import TextInput from "../components/utils/text-input"
import { useNavigate } from "react-router-dom"

export default function Login() {
    const [email, setEmail] = useState("")
    const [pwd, setPwd] = useState("")
    const [err, setErr] = useState(undefined)
    const navigate = useNavigate()

    async function login()
    {
            const response = await fetch(
            "https://app.promo-assurance.fr/api/auth/login",
            {
              method: "POST",
              body: JSON.stringify({
                email: email,
                password: pwd
              }),
              headers: new Headers({'content-type': 'application/json'}),

            }
        );
        if (response.status === 401) {
            const reqerr = await response.json()
            console.log(reqerr)
            setErr(reqerr.error)
        } else if (response.status === 200) {
            const data = await response.json()
            setErr(undefined)
            localStorage.setItem('token', data.access_token);
            localStorage.setItem('refresh', data.refresh_token);
            navigate('/docs')
        } else {
            setErr("Quelque chose ne s'est pas passé comme prévu, merci de rééssayer plus tard")
        }
    }


    return <div className="w-full h-[100vh] flex justify-center items-center">
        <div className="w-1/3 rounded-md p-4 border-gray-300 border-solid border-2 flex flex-col gap-3">
            <div>
                <TextInput onKeyDown={(e) => {
                    if (e.key === "Enter")
                        login()
                }} className="w-full" label="Adresse email" placeholder="Adresse email : exemple@gmail.com" onChange={(e) => {setEmail(e.target.value.toLowerCase()); setErr(undefined)}} value={email}></TextInput>
            </div>
            <div>
                <TextInput onKeyDown={(e) => {
                    if (e.key === "Enter")
                        login()
                }} label="Mot de passe"  password placeholder="Mot de passe" onChange={(e) => {setPwd(e.target.value); setErr(undefined)}} value={pwd}></TextInput>
            </div>
            <div className="w-full flex justify-center">
                <button
                    className="flex flex-row bg-promo-green w-1/2 py-3 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
                    onClick={() => {
                        login()
                    }}>
                        Se connecter
                </button>
            </div>
            {
                err ? <div className="w-full text-center text-red-600">{err}</div> : <></> 
            }
            <div></div>
        </div>
    </div>
}