import React, { useState, useEffect } from 'react';
import TextInput from '../utils/text-input';
import SelectInput from '../utils/select-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { recoAdded, notesAdded, besoinsAdded, montantAdded, formuleAdded } from '../../root-reducer';
import FileUploader from '../utils/file-uploader';
import FileUploaderSmall from '../utils/file-uploader-small';
import Compressor from "compressorjs";
import DateInput from "../utils/date-input";

function Content(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const state = useSelector(state => state.root)

    const [date1, setDate1] = useState('');
    const [date2, setDate2] = useState('');

    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`;
    const [otherCompagnie, setOtherCompagnie] = useState('');

    function usePage() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]).get("page");
    }


    const [familyName, setFamilyName] = useState('');
    const initialState = {
        lastname: state.permis.nom,
        firstname: state.permis.prenom,
        phone: state.divers.phone_number ? state.divers.phone_number : "",
        email: state.divers.email ? state.divers.email : "",
        address : state.antecedents.global.adresse.numero + " " + state.antecedents.global.adresse.rue,
        city: state.antecedents.global.adresse.ville,
        zip:  state.antecedents.global.adresse.code_postal
    }
    const [response, setResponse] = useState(
        usePage() === 1 ?
        {
        lastname: '',
        firstname: '',
        phone: '',
        email: '',
        address: '',
        zip: '',
        city: ''
    } : initialState
);


    const [formule, setFormule] = useState('');
    const [clientReco, setClientReco] = useState('');
    const [immatriculation, setImmatriculation] = useState(usePage() === 1 ? '' : state.carte_grise.immatriculation);
    const [compagnie, setCompagnie] = useState('none');
    const [montant, setMontant] = useState('');
    const [notes, setNotes] = useState('');
    const [besoin, setBesoin] = useState('');

    const [doc1, setDoc1] = useState()
    const [doc2, setDoc2] = useState()
    const [doc3, setDoc3] = useState()

    function fileHandler(f, setter) {
        const file = f.target.files[0];
        console.log("Original File Size:", file.size);
        if (file.size > 5 * 1024 * 1024) {
          new Compressor(file, {
            quality: 0.7,
            success(result) {
              console.log("Compressed File Size:", result.size);
              setter(result);
            },
            error(err) {
              console.log(err.message);
            },
          });
        } else {
          setter(file);
        }
    }

    const handleSearch = async () => {
        try {
            const response = await fetch('https://app.promo-assurance.fr/api/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ familyName })
            });

            const data = await response.json();
            if (response.ok) {
                setResponse(data.results[0].properties);
            } else {
                console.error('Error fetching family name:', data.error);
                setResponse({
                    lastname: '',
                    firstname: '',
                    phone: '',
                    email: '',
                    address: '',
                    zip: '',
                    city: ''
                });
            }
        } catch (error) {
            console.error('Error fetching family name:', error);
        }
    };

    function setFileInputs() {
        switch (compagnie) {
            case "oyat":
                return <>
                    <FileUploaderSmall text="Notice" onChange={e => fileHandler(e, setDoc1)}/>
                    <FileUploaderSmall text="Mandat SEPA" onChange={e => fileHandler(e, setDoc2)}/>
                </>
            case "zephir":
                return <>
                <FileUploaderSmall text="Dispositions"  onChange={e => fileHandler(e, setDoc1)}/>
                <FileUploaderSmall text="Mandat SEPA"  onChange={e => fileHandler(e, setDoc2)}/>
                <FileUploaderSmall text="Info zephir"  onChange={e => fileHandler(e, setDoc3)}/>
            </>
            default:
                return <></>
        }
    }

    useEffect(() => {

    }, [response]);


    const handleCompagnieChange = (value) => {
        setCompagnie(value);
        if (value !== 'other') {
            setOtherCompagnie(''); // Reset otherCompagnie if not "other"
        }
    };
    const handleResponseChange = (field, value) => {
        setResponse((prevResponse) => ({
            ...prevResponse,
            [field]: value
        }));
    };

    const handleValidation = async () => {
        console.log("Hi")
        const addressParts = response.address.match(/^(\d+)\s*(.*)$/);
        const numero = addressParts ? addressParts[1] : '';
        const rue = addressParts ? addressParts[2] : response.address;
        const selectedCompagnie = compagnie === 'other' ? otherCompagnie : compagnie;
        console.log(immatriculation)
        try {
            //Doit match avec les champs attendus en back sign_app.py
            //Split l'adresse en : ["adresse"]["numero"],["adresse"]["rue"],["adresse"]["code_postal"],["adresse"]["ville"]
            const formData = new FormData()
            formData.append("json", JSON.stringify(
                {
                    name: response.lastname,
                    first_name: response.firstname,
                    phone_number: response.phone,
                    email: response.email,
                    adresse: {
                        numero: numero,
                        rue: rue,
                        code_postal: response.zip,
                        ville: response.city
                    },
                    formula: formule,
                    reco: clientReco,
                    immatriculation,
                    selectedCompagnie,
                    montant,
                    notes,
                    besoins: besoin,
                    date1: date1,
                    date2: date2
                }));

            console.debug("Hello" + formData)
            let final_compagnie = compagnie
            if (doc1) formData.append("notice", doc1);
            if (doc2) formData.append("mandat", doc2);
            if (doc3) formData.append("zephir_doc", doc3);

            if (!doc1 && !doc2 ) {
                final_compagnie = 'none';
            }
            if (final_compagnie == 'other') {
                final_compagnie = 'none'
            }
            console.log("Final_compagnie : " + final_compagnie)
            console.log("Compagnie : " + compagnie)
            console.log("Other compagnie : " + otherCompagnie)
                try {
                    const response_req = await fetch(`https://app.promo-assurance.fr/api/signature/${final_compagnie}`, {
                        method: 'POST',
                        body: formData,
                    });

                    const data = await response_req.json();

                    if (response_req.ok) {
                        console.log('Data successfully submitted:', data);
                        return; // Exit the function if the request is successful
                    } else {
                        console.error('Error submitting data:', data.error);
                    }
                } catch (error) {
                    console.error('Error submitting data:', error);
                }
            } catch(error) {
            console.error(error)
        }
    }

    return (
        <div className="h-5/6 flex flex-col items-center p-4">
            <div className="w-full flex flex-col items-center justify-center mt-12">
                <div className="w-2/3 sm:w-1/2 lg:w-1/3 flex flex-row items-center justify-center mb-4">
                    <TextInput
                        placeholder="Nom de famille"
                        value={familyName}
                        onChange={(e) => setFamilyName(e.target.value)}
                        className="flex-grow p-2 border border-gray-300 rounded-md"
                    />
                    <button onClick={handleSearch} className="ml-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
                        Chercher
                    </button>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-2/3 sm:w-1/2 lg:w-2/3">
                    <TextInput
                        placeholder="Nom"
                        label="Nom"
                        value={response.lastname}
                        className="border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                        onChange={(e) => handleResponseChange('lastname', e.target.value)}
                    />
                    <TextInput
                        placeholder="Prénom"
                        label="Prénom"
                        value={response.firstname}
                        className="border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                        onChange={(e) => handleResponseChange('firstname', e.target.value)}
                    />
                    <TextInput
                        placeholder="Adresse"
                        label="Adresse"
                        value={response.address}
                        className="border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                        onChange={(e) => handleResponseChange('address', e.target.value)}
                    />
                    <TextInput
                        placeholder="Immatriculation"
                        label="Immatriculation"
                        value={immatriculation}
                        className="border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                        onChange={(e) => {
                            let val = e.target.value.replace(/[^\w]/g, "");
                            if (/^\d{2}/.test(val)) {
                                // Si les deux premiers caractères sont des chiffres
                                if (val.length <= 3) {
                                val = val;
                                } else if (val.length <= 6) {
                                val = `${val.slice(0, 3)}-${val.slice(3)}`;
                                } else {
                                val = `${val.slice(0, 3)}-${val.slice(3, 6)}-${val.slice(6)}`;
                                }
                            } else if (/^[a-zA-Z]{2}/.test(val)) {
                                // Si les deux premiers caractères sont des lettres
                                if (val.length <= 2) {
                                val = val;
                                } else if (val.length <= 5) {
                                val = `${val.slice(0, 2)}-${val.slice(2)}`;
                                } else {
                                val = `${val.slice(0, 2)}-${val.slice(2, 5)}-${val.slice(5, 7)}`;
                                }
                            }
                            setImmatriculation(val) }
                        }
                    />
                    {/* <TextInput
                        placeholder="Compagnie"
                        label="Compagnie"
                        value={compagnie}
                        className="border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                        onChange={(e) => setCompagnie(e.target.value)}
                    /> */}
                    <SelectInput 
                        label="Compagnie"
                        placeholder="Compagnie"
                        options={
                            [
                                { value: 'zephir', label: 'Zephir' },
                                { value: 'oyat', label: 'Oyat' },
                                { value: 'solly', label: 'Solly' },
                                { value: 'wazari', label: 'Wazari' },
                                { value: 'luxior', label: 'Luxior' },
                                { value: 'april', label: 'April' },
                                { value: 'other', label: 'Autre compagnie'},
                                { value: 'none', label: 'Aucune compagnie'}
                            ]
                        }
                        value={compagnie}
                        className="border border-gray-300 focus-visible:outline-none px-6 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                        onChange={(e) => {handleCompagnieChange(e.value); console.log(e.value); }}
                    />
                                {compagnie === 'other' && (
                <TextInput
                    placeholder="Nom de l'autre compagnie"
                    label="Autre Compagnie"
                    value={otherCompagnie}
                    className="border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                    onChange={(e) => setOtherCompagnie(e.target.value)}
                />
            )}
                    <TextInput
                        placeholder="Téléphone"
                        label="Telephone"
                        value={response.phone}
                        className="border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                        onChange={(e) => handleResponseChange('phone', e.target.value)}
                    />
                    <TextInput
                        placeholder="Email"
                        label="Email"
                        value={response.email}
                        className="border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                        onChange={(e) => handleResponseChange('email', e.target.value)}
                    />
                    <SelectInput
                        label="Formule"
                        options={[
                            { value: 'formule1', label: 'Formule 1' },
                            { value: 'formule2', label: 'Formule 2' },
                            { value: 'formule3', label: 'Formule 3' }
                        ]}
                        value={formule}
                        onChange={(e) => setFormule(e.value)}
                        className="border border-gray-300 focus-visible:outline-none px-6 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                    />
                    <SelectInput
                        label="Le client suit-il les recommendations ?"
                        options={[
                            { value: 1, label: 'Oui' },
                            { value: 0, label: 'Non' }
                        ]}
                        value={clientReco}
                        onChange={(e) => setClientReco(e.value)}
                        className="border border-gray-300 focus-visible:outline-none px-6 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                    />
                    <TextInput
                        placeholder="Montant"
                        label="Montant"
                        value={montant}
                        className="border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                        onChange={(e) => setMontant(e.target.value)}
                    />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-2/3 mt-6">
                    <textarea
                        placeholder="Notes diverses"
                        label="Notes"
                        value={notes}
                        className="col-span-1 border border-gray-300 focus-visible:outline-none p-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                        onChange={(e) => setNotes(e.target.value)}
                        rows="2"
                    />
                    <textarea
                        placeholder="Besoins exprimés ne pouvant être satisfait"
                        label="Besoin"
                        value={besoin}
                        className="col-span-1 border border-gray-300 focus-visible:outline-none p-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                        onChange={(e) => setBesoin(e.target.value)}
                        rows="2"
                    />
                        <label className="text-5xl text-blue-400 w-full block text-center">Dates non sinistré:</label>

                        <div className="flex flex-col sm:flex-row w-3/3 gap-4">
                            <DateInput
                                placeholder="Première date"
                                label="Première date"
                                value={date1}
                                onChange={(e) => setDate1(e)}
                                className="border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                            />
                            <DateInput
                                placeholder="Deuxième date"
                                label="Deuxième date"
                                value={date2}
                                onChange={(e) => setDate2(e)}
                                className="border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105"
                            />
                        </div>
                </div>
                <div className='flex flex-row w-full justify-center gap-4 mt-4'>
                    {
                        setFileInputs()
                    }
                </div>
                <button onClick={handleValidation} className="absolute bottom-0 mb-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-green-600">
                                Valider
                            </button>
                <div className='w-full flex flex-row items-center justify-center absolute bottom-5'>
                    {/* Add buttons or additional elements here */}
                </div>
            </div>
        </div>
    );
}

export default Content;
