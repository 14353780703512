import React from 'react';

function TextAreaInput(props) {
    const classname = props.value === "" || !props.value ? "py-4" : "pt-5 pb-3"

    return ( 
        <div className="relative w-full ">
            <div className="bg-white px-1 absolute text-xs text-promo-green font-bold left-5 top-[2px]" style={{display: props.value === "" || !props.value ? "none" : "block"}}>{props.placeholder}</div>
            {props.icon ? (<props.icon className="h-full absolute right-5 text-promo-green" size={25}/>) : (<></>)}
            <textarea rows="4" disabled={props.disabled} className={"w-full bg-[#F5F7F8] focus-visible:outline-none px-6 placeholder-light-black " + classname} value={props.value ? props.value : ""} placeholder={props.placeholder} onChange={e => props.onChange(e)}/>
        </div> 
    );
}

export default TextAreaInput;