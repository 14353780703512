import React from "react";
import Drawer from "../components/drawer/drawer";
import UpperBar from "../components/upper-bar/upper-bar";
import Content from "../components/conducteur/content";
import packageJson from "../../package.json";

export default function Conducteur(props) {
  const version = packageJson.version;
  return (
    <div className="w-screen h-screen flex flex-col overflow-hidden">
      {/* Drawer en haut */}
      <UpperBar step={"2"} />
      <div className="w-full">
        {" "}
        {/* Ajout de margin-top pour décaler le Drawer vers le bas */}
        <Drawer name="Conducteur"></Drawer>
      </div>
      {/* Contenu principal avec UpperBar à gauche */}
      <div className="flex flex-grow overflow-hidden">
        {/* UpperBar à gauche */}
        <div className="w-0/6 h-full flex-shrink-0 overflow-y-auto">
        </div>
        {/* Contenu principal centré */}
        <div className="flex-grow h-full overflow-y-auto">
          <Content />
        </div>
      </div>
    </div>
  );
}
