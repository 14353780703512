import React from "react";
import { BiDownload } from "react-icons/bi";
import { FaCheck, FaIdCard } from "react-icons/fa6";
import { HiMenu } from "react-icons/hi";

export default function FileUploaderSmall(props) {
  return (
    <div className={"w-[15%] h-[140px] flex flex-row " + props.className}>
      <label
        className="w-full h-full relative border-2 border-dashed border-promo-grey-text rounded-md p-2 flex flex-col justify-between"
        htmlFor="formId"
      >

        <div
          className="flex items-center justify-center text-base font-semibold"
          style={{ color: props.hasFiles ? "#0077B6" : "#474747" }}
        >
          <div>{props.text}</div>
        </div>
        <div className="flex flex-col w-full text-promo-grey-text items-center text-[10px] text-center ">
          <div>{props.textcompress}</div>
          <BiDownload size={30} />
          GLISSER ET DEPOSER VOTRE FICHIER
        </div>
        <div className="flex flex-row w-full border-2 border-promo-green rounded-md items-center justify-around text-promo-green px-4">
          <div className="text-promo-green items-center text-[10px] font-semibold text-center">
            OU IMPORTER DEPUIS
          </div>
          <HiMenu />
        </div>
        <input
          className="absolute top-0 bottom-0 right-0 left-0"
          type="file"
          id="formId"
          style={{ opacity: 0, cursor: "pointer" }}
          multiple={props.multiple}
          accept={props.accept}
          onChange={props.onChange}
        />
      </label>
    </div>
  );
}
