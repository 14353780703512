import React from "react";
import { HiDocumentText } from "react-icons/hi";
import TextInput from "../utils/text-input";
import { useSelector, useDispatch } from "react-redux";
import { NatureSinistre, ResponsabiliteSinistre } from "./select-data";

function CardResume(props) {
  console.log("Rentre dans le resume")
  console.log("Root resume", useSelector((state) => state.root))
  const global = useSelector((state) => state.root.antecedents.global);
  const releves = useSelector((state) => state.root.antecedents.releves);
  
  return (
    <div className="w-full flex flex-row items-center justify-center h-5/6 gap-8">
      <div className="w-[40%] p-2 border-[#F5F7F8] border-[3px] rounded-md flex flex-col gap-4">
        <div className="w-full bg-[#F5F7F8] rounded-md flex items-center justify-center text-promo-blue p-4">
          <HiDocumentText size={70} />
        </div>
        <div className="w-full text-center font-semibold"> Résumé </div>
        <div className="flex flex-row gap-8 w-full justify-between">
          <TextInput
            value={global.bonus.value}
            placeholder="Bonus/malus"
            label="CRM"
            disabled
          />
          <TextInput
            value={global.bonus.anciennete.toString()}
            placeholder="Mois pris en compte"
            label="Nombre de mois d'assurance"
            disabled
          />
        </div>
        {releves.map((releve, index) => (
            <React.Fragment key={index}>
              {releve.sinistres && releve.sinistres.length > 0 ? (
                releve.sinistres.map((sinistre) => (
                  <div
                    key={sinistre.id} // Utiliser l'id au lieu de l'index comme clé
                    className="flex flex-row items-center justify-center"
                  >
                    <div className="w-full text-center">
                      {sinistre.date &&
                        `${sinistre.date}`}
                    </div>
                    <div className="w-full text-center">
                      {sinistre.responsabilite &&
                        ResponsabiliteSinistre.find(
                          (responsabilite) =>
                            responsabilite.value === sinistre.responsabilite
                        )?.label}
                    </div>
                    <div className="w-full text-center">
                      {
                        NatureSinistre.find(
                          (nature) => nature.value === sinistre.nature
                        )?.label
                      }
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex flex-row items-center justify-center">
                  <div className="w-full text-center">Pas de sinistre</div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
  );
}

export default CardResume;
