import React from "react";
import FileUploader from "../utils/file-uploader";
import { FaIdCard } from "react-icons/fa6";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsTrashFill } from "react-icons/bs";
import { countries, civilite } from '../conducteur/countries.js';
import {
  permisRectoAdded,
  permisVersoAdded,
  carteGriseAdded,
  //relevePictureAdded,
  bonusGlobalAdded,
  dateEffetAdded,
  addSinistres,
  nameAdded,
  firstNameAdded,
  birthdateAdded,
  licenseDateAdded,
  civiliteAdded,
  telAdded,
  emailAdded,
  adresseNumeroAdded,
  adresseRueAdded,
  adresseCodePostalAdded,
  adresseVilleAdded,
  paysAdded,
  immatriculationAdded,
  achatAdded,
  chevauxAdded,
  birthdayCarAdded,
  //vehiculeAssureAdded,
  dateResilAdded,
  //trackerAdded,
  financementAdded,
  addReleve,
 //sinistreAdded,
  clearReleves,
  adresseComplementAdded,
} from "../../root-reducer";
import toast from "react-hot-toast";
import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import Compressor from "compressorjs";

export default function Content(props) {
  const getCountryValue = (countryName) => {
    const country = countries.find(c => c.label === countryName);
    return country ? country.value : "61";  // Retourne "61" par défaut si le pays n'est pas trouvé
  };  
  const getCiviliteValue = (civiliteName) => {
    const civil = civilite.find(c => c.value === civiliteName);
    return civil ? civil.value : "0";
  };  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const docs = useSelector((state) => state.root.pictures);
  const [ri, setRi] = useState([]);
  const [key, setKey] = useState(Date.now());
  const [hasPermisRectoFiles, setHasPermisRectoFiles] = useState(false);
  const [hasPermisVersoFiles, setHasPermisVersoFiles] = useState(false);
  const [hasCarteGriseFiles, setHasCarteGriseFiles] = useState(false);
  const [hasReleveFiles, setHasReleveFiles] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [compressionPermisRectoMessage, setCompressionPermisRectoMessage] =
    useState("");
  const [compressionPermisVersoMessage, setCompressionPermisVersoMessage] =
    useState("");
  const [compressionCarteGriseMessage, setCompressionCarteGriseMessage] =
    useState("");
  const [compressionReleveMessage, setCompressionReleveMessage] = useState("");
  const today = new Date();
  const formattedDate = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`;
  const essais = 0;
  
  async function permisRectoHandler(e) {
    const file = e.target.files[0];
    console.log("Original File Size:", file.size);
    if (file.size > 5 * 1024 * 1024) {
      new Compressor(file, {
        quality: 0.7,
        success(result) {
          console.log("Compressed File Size:", result.size);
          setCompressionPermisRectoMessage(
            `L'image a été compressée de ${(file.size / (1024 * 1024)).toFixed(
              2
            )} Mo à ${(result.size / (1024 * 1024)).toFixed(2)} Mo`
          );
          dispatch(permisRectoAdded(result));
          setHasPermisRectoFiles(true);
        },
        error(err) {
          console.log(err.message);
        },
      });
    } else {
      dispatch(permisRectoAdded(file));
      setHasPermisRectoFiles(true);
    }
  }
  
  async function permisVersoHandler(e) {
    const file = e.target.files[0];
    console.log("Original File Size:", file.size);
    if (file.size > 5 * 1024 * 1024) {
      new Compressor(file, {
        quality: 0.7,
        success(result) {
          console.log("Compressed File Size:", result.size);
          setCompressionPermisVersoMessage(
            `L'image a été compressée de ${(file.size / (1024 * 1024)).toFixed(
              2
            )} Mo à ${(result.size / (1024 * 1024)).toFixed(2)} Mo`
          );
          dispatch(permisVersoAdded(result));
          setHasPermisVersoFiles(true);
        },
        error(err) {
          console.log(err.message);
        },
      });
    } else {
      dispatch(permisVersoAdded(file));
      setHasPermisVersoFiles(true);
    }
  }
  
  async function carteGriseHandler(e) {
    const file = e.target.files[0];
    console.log("Original File Size:", file.size);
    if (file.size > 5 * 1024 * 1024) {
      new Compressor(file, {
        quality: 0.7,
        success(result) {
          console.log("Compressed File Size:", result.size);
          setCompressionCarteGriseMessage(
            `L'image a été compressée de ${(file.size / (1024 * 1024)).toFixed(
              2
            )} Mo à ${(result.size / (1024 * 1024)).toFixed(2)} Mo`
          );
          dispatch(carteGriseAdded(result));
          setHasCarteGriseFiles(true);
        },
        error(err) {
          console.log(err.message);
        },
      });
    } else {
      dispatch(carteGriseAdded(file));
      setHasCarteGriseFiles(true);
    }
  }
  
  async function releveHandler(e) {
    const files = Array.from(e.target.files);
    const compressedFilesPromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        if (file.size > 5 * 1024 * 1024) {
          new Compressor(file, {
            quality: 0.7,
            success(result) {
              console.log("Compressed File Size:", result.size);
              setCompressionReleveMessage(
                `L'image a été compressée de ${(file.size / (1024 * 1024)).toFixed(
                  2
                )} Mo à ${(result.size / (1024 * 1024)).toFixed(2)} Mo`
              );
              resolve(result);
            },
            error(err) {
              console.log(err.message);
              reject(err);
            },
          });
        } else {
          resolve(file);
        }
      });
    });
  
    try {
      const compressedFiles = await Promise.all(compressedFilesPromises);
      setRi((prevState) => [...prevState, ...compressedFiles]);
      setHasReleveFiles(true);
    } catch (err) {
      console.error(
        "Une erreur s'est produite lors de la compression des fichiers :",
        err
      );
    }
  }
  
  function handleDelete(index) {
    setRi((prevState) => {
      const newRi = prevState.filter((_, i) => i !== index);
      setHasReleveFiles(newRi.length > 0);
      return newRi;
    });
    setKey(Date.now());
  }

  async function handleNext() {
    setIsLoading(true);
    if (!docs.permisRecto) {
      dispatch(nameAdded(""));
      dispatch(firstNameAdded(""));
      dispatch(birthdateAdded(""));
      dispatch(licenseDateAdded(""));
      dispatch(telAdded(""));
      dispatch(emailAdded(""));
      dispatch(
        adresseNumeroAdded(
          ""
        )
      );
      dispatch(
        adresseRueAdded("")
      );
      dispatch(
        adresseCodePostalAdded(
          ""
        )
      );
      dispatch(
        adresseVilleAdded(
          ""
        )
      );
      dispatch(
        adresseComplementAdded(
          ""
        )
      );
      dispatch(
        immatriculationAdded("")
      );
      dispatch(achatAdded(""));
      dispatch(chevauxAdded(""));
      dispatch(
        birthdayCarAdded("")
      );
      dispatch(dateResilAdded(""));
      dispatch(financementAdded(""));
      dispatch(
        bonusGlobalAdded("")
      );
      dispatch(
        addSinistres([])
      );
      dispatch(dateEffetAdded(formattedDate));
      dispatch(financementAdded("comptant"));
      navigate("/conducteur");
      toast.error("Glissez au moins un permis de conduire valide");
    } else {
      const formData = new FormData();
      formData.append("licenseFile", docs.permisRecto);
      formData.append("licenseFileVerso", docs.permisVerso);
      formData.append("cg", docs.carteGrise);
      ri.forEach((file, index) => {
        formData.append(`ri${index + 1}`, file);
      });

      try {
        const response = await fetch(
          "https://app.promo-assurance.fr/api/get-document",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          const countryName = responseData.data.permis.country;
          const countryValue = getCountryValue(countryName);
          const civiliteName = responseData.data.permis.civilite;
          const civiliteValue = getCiviliteValue(civiliteName);
          console.log("================================================");
          console.log(responseData);
          console.log("================================================");
          dispatch(permisRectoAdded(responseData.recto_image_base64));
          dispatch(permisVersoAdded(responseData.verso_image_base64));
          dispatch(carteGriseAdded(responseData.carteGrise));
          dispatch(nameAdded(responseData.data.permis.name));
          dispatch(firstNameAdded(responseData.data.permis.first_name));
          dispatch(birthdateAdded(responseData.data.permis.birthdate));
          dispatch(licenseDateAdded(responseData.data.permis.license_date));
          dispatch(civiliteAdded(civiliteValue));
          dispatch(telAdded(responseData.data.divers.phone_number));
          dispatch(emailAdded(responseData.data.divers.email));
          dispatch(
            adresseRueAdded(responseData.data.antecedents.global.adresse.numero + " " +responseData.data.antecedents.global.adresse.rue)
          );
          dispatch(
            adresseCodePostalAdded(
              responseData.data.antecedents.global.adresse.code_postal
            )
          );
          dispatch(
            adresseVilleAdded(
              responseData.data.antecedents.global.adresse.ville
            )
          );
          dispatch(
            adresseComplementAdded(
              responseData.data.antecedents.global.adresse.complement
            )
          );
          dispatch(paysAdded(countryValue));
          dispatch(
            immatriculationAdded(responseData.data.carte_grise.immatriculation)
          );
          dispatch(achatAdded(responseData.data.carte_grise.purchase_date));
          dispatch(chevauxAdded(responseData.data.carte_grise.tax_horsepower));
          dispatch(
            birthdayCarAdded(responseData.data.carte_grise.birthday_car)
          );
          //Meme raison qu'au dessus
          //dispatch(
          //  vehiculeAssureAdded(responseData.data.carte_grise.vehicule_assure)
          //);
          dispatch(dateResilAdded(responseData.data.carte_grise.date_resil));
          //dispatch(trackerAdded(responseData.data.carte_grise.tracker));
          dispatch(financementAdded(responseData.data.carte_grise.financement));
          dispatch(
            addSinistres(responseData.data.antecedents.global.sinistres)
          );
          dispatch(
            bonusGlobalAdded(responseData.data.antecedents.global.bonus.value)
          );
          dispatch(dateEffetAdded(formattedDate));
          dispatch(financementAdded("comptant"));
          if (responseData.data.antecedents.releves.length > 0) {
            const releveData = {
              bonus_malus: responseData.data.antecedents.releves.bonus_malus,
              date_echeance: responseData.data.antecedents.releves.bonus_malus,
              date_edition: responseData.data.antecedents.releves.bonus_malus,
              date_effet: responseData.data.antecedents.releves.bonus_malus,
              date_resiliation:
                responseData.data.antecedents.releves.bonus_malus,
              motif_resiliation:
                responseData.data.antecedents.releves.bonus_malus,
              resilie: responseData.data.antecedents.releves.bonus_malus,
              sinistres: [],
            };
            let nextId = 0;
            dispatch(clearReleves());
            for (const releve of responseData.data.antecedents.releves) {
              dispatch(
                addReleve({
                  ...releve,
                  id: nextId++,
                })
              );
            }
          } else {
            console.log("Aucun relevé trouvé");
          }

          navigate("/conducteur");
        } else {
          const errorData = await response.json();
          console.error("Erreur de réponse:", errorData);
          if (essais < 4) {
            essais++;
            toast.error(
              "Erreur lors de l'envoi des documents. Nouvel essai en cours."
            );
            handleNext();
          } else {
            toast.error(
              "Impossible de récupérer les données des documents. Veuillez réessayer."
            );
          }
        }
      } catch (error) {
        console.error("Erreur lors de la connexion au serveur:", error);
        toast.error(
          `Problème de connexion au serveur. Détails: ${error.message}`
        );
      }
      setIsLoading(false);
    }
  }

  return (
    <div className=" h-5/6 w-full flex flex-col items-center">
      <div className="text-2xl font-semibold text-promo-text my-6">
        Importez vos documents ou passez directement à l'étape suivante
      </div>
      <div className="flex w-[60%] justify-center flex-col gap-8">
        <div className="flex flex-row w-full gap-16 justify-center">
          <FileUploader
            multiple={false}
            text={"Recto du permis"}
            textcompress={compressionPermisRectoMessage}
            icon={FaIdCard}
            onChange={permisRectoHandler}
            hasFiles={hasPermisRectoFiles}
            accept=".pdf, image/*"
          />
          <FileUploader
            multiple={false}
            text={"Verso du permis"}
            textcompress={compressionPermisVersoMessage}
            icon={FaIdCard}
            onChange={permisVersoHandler}
            hasFiles={hasPermisVersoFiles}
            accept=".pdf, image/*"
          />
        </div>

        <div className="flex flex-row w-full gap-16 justify-center">
          <FileUploader
            multiple={false}
            text={"Carte grise"}
            textcompress={compressionCarteGriseMessage}
            onChange={carteGriseHandler}
            hasFiles={hasCarteGriseFiles}
            accept=".pdf, image/*"
          />
          <FileUploader
            key={key}
            multiple={true}
            text={"Relevé(s) d'information(s)"}
            textcompress={compressionReleveMessage}
            onChange={releveHandler}
            accept=".pdf, image/*"
            hasFiles={hasReleveFiles}
          />
          <div className="absolute right-[50px]">
            {ri.map((file, index) => {
              const fileName = file.name.split(".").slice(0, -1).join(".");
              return (
                <div
                  key={index}
                  className="flex items-center justify-between p-2 gap-2 bg-white shadow rounded-lg"
                >
                  <span className="text-gray-800">{fileName}</span>
                  <button
                    onClick={() => handleDelete(index)}
                    className="text-red-500 cursor-pointer flex items-center justify-center"
                  >
                    <BsTrashFill size={25} />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full justify-center fixed bottom-10 left-0 right-0">
  <button
    className={`bg-promo-green hover:bg-promo-dark-green hover:scale-105 flex flex-row w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative rounded-full shadow-lg transition-all duration-300 ease-in-out`}
    onClick={() => navigate('/hubspot')}
  >
    <div>Client hubspot</div>
  </button>
  <button
    className={`flex flex-row w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative rounded-full shadow-lg transition-all duration-300 ease-in-out transform ${
      isLoading
        ? "opacity-50 cursor-not-allowed bg-promo-dark-green"
        : "bg-promo-green hover:bg-promo-dark-green hover:scale-105"
    }`}
    onClick={handleNext}
    disabled={isLoading}
  >
    {isLoading ? (
      <FaSpinner className="animate-spin" />
    ) : (
      <>
        <div>ETAPE SUIVANTE</div>
        <BsArrowRightCircleFill size={25} className="absolute right-5" />
      </>
    )}
  </button>
</div>

    </div>
  );
}
