import React from "react";

function TextInput(props) {
  const {
    value,
    label,
    placeholder,
    onChange,
    onBlur,
    disabled,
    maxLength,
    icon: Icon,
    classname,
    password,
    onKeyDown,
  } = props;
  //const classname = value === "" || value === undefined ? "py-4" : "pt-5 pb-3";
  const disabledStyle = disabled ? "bg-gray-200" : "";
  let labelStyle = "px-1 absolute text-xs text-blue-500 font-bold left-5 bottom-12 z-10 hover:border-blue-500"
  if (!disabled) {
    labelStyle += " bg-white"
  } else {
    labelStyle += " bg-gray-200 rounded-lg pt-1 px-1"
  }

  // Gestionnaire pour la saisie de l'utilisateur
  const handleChange = (e) => {
    const { value } = e.target;
    if (maxLength && value.length > maxLength) {
      return; // Empêche la saisie au-delà de maxLength
    }
    onChange(e); // Appelle le gestionnaire onChange passé en prop
  };


  return (
    <div className="relative w-full">
      <div
        className={labelStyle }
        style={{
          display: value === "" || value === undefined ? "none" : "block",
        }}
      >
        {label}
      </div>
      {Icon && (
        <Icon className="h-full absolute right-5 text-promo-green" size={25} />
      )}
      <input
        type={password ? "password" : "text"}
        disabled={disabled}
        className={`w-full border border-gray-200 p-4 px-6 placeholder:italic placeholder:text-gray-400 ${disabledStyle} rounded-lg transition-transform duration-200 ease-in-out transform ${classname}`}
        value={value !== undefined && value !== null ? value : ""}
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur} // Ajoute l'événement onBlur pour gérer la perte de focus
        maxLength={maxLength}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}

export default TextInput;