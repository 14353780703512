import { useState } from "react"
import TextInput from "../components/utils/text-input"
import { useNavigate } from "react-router-dom"

export default function Login() {
    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")
    const [pwd, setPwd] = useState("")
    const [err, setErr] = useState(undefined)
    const navigate = useNavigate()


    async function login()
    {
            const response = await fetch(
            "https://app.promo-assurance.fr/api/auth/register",
            {
              method: "POST",
              body: JSON.stringify({
                email: email,
                username: username,
                password: pwd,
              }),
              headers: new Headers({'content-type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}),

            }
        );
        if (response.status === 401) {
            setErr("Il faut les droits d'administrateur pour creer un nouvel utilisateur")
        } else if (response.status === 201) {
            setErr(undefined)
        } else {
            setErr("Ce nom d'utilisateur et/ou adresse email existe(nt) deja")
        }
    }


    return <div className="w-full h-[100vh] flex justify-center items-center">
        <div className="w-1/3 rounded-md p-4 border-gray-300 border-solid border-2 flex flex-col gap-3">
            <div>
                <TextInput className="w-full" label="Adresse email" placeholder="Adresse email : exemple@gmail.com" onChange={(e) => {setEmail(e.target.value); setErr(undefined)}} value={email}></TextInput>
            </div>
            <div>
                <TextInput className="w-full" label="Nom d'utilisateur" placeholder="Nom d'utilisateur" onChange={(e) => {setUsername(e.target.value.toLowerCase()); setErr(undefined)}} value={username}></TextInput>
            </div>
            <div>
                <TextInput label="Mot de passe" placeholder="Mot de passe" onChange={(e) => {setPwd(e.target.value); setErr(undefined)}} value={pwd}></TextInput>
            </div>
            <div className="w-full flex justify-center">
                <button
                    className="flex flex-row bg-promo-green w-1/2 py-3 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
                    onClick={() => {
                        login()
                    }}>
                        Inscrire un nouvel utilisateur
                </button>
            </div>
            {
                err ? <div className="w-full text-center text-red-600">{err}</div> : <></> 
            }
        </div>
        <button
            className="absolute bg-promo-green w-1/6 py-3 text-white items-center justify-center font-semibold hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 top-5 left-5"
            onClick={() => {
                navigate('/docs')
                }}>
            {"<-"} Retour au menu
        </button>

    </div>
}