function nav(location) {
    window.location.href = location
}

async function refreshToken() {
    if (localStorage.getItem("refresh") === null) {
        nav("/login")
    } else {
        const res = await fetch("https://app.promo-assurance.fr/api/auth/refresh", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem("refresh"),
            },
            body: "",
          })
        const data = await res.json()
        if (res.status === 200) {
            localStorage.setItem('token', data.access_token);
            localStorage.setItem('refresh', data.refresh_token);
        } else {
            nav('/login')
        }
    }
}

export async function checkLogin() {
    if (localStorage.getItem("token") === null) {
        await refreshToken()
    } else {
        let err = false
        const res = await fetch("https://app.promo-assurance.fr/api/auth/test-token", {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
                "Content-Type": "application/json"
            }
        }).catch(() => {
            err = true
        })
        if (err === true) {
            nav('/login')
            return
        }
        if (res.status !== 200) {
            await refreshToken()
        }
        // console.log(res)
        // const data = await res.json()
        // if (data.msg === "Token has expired") {
        //     await refreshToken()
        // }
    }
}