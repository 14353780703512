import React from 'react';
import Drawer from '../components/drawer/drawer';
import DrawerImage from '../components/drawer/drawer-image';
import UpperBar from '../components/upper-bar/upper-bar';
import Content from '../components/logs/content';
import packageJson from '../../package.json'; 

function AppLogs(props) {

    return (
        <div className="w-screen h-screen flex flex-col overflow-hidden">
          {/* Drawer en haut */}
          <UpperBar step={"1"} />
          <div className="w-full mt-12">
            {" "}
            {/* Ajout de margin-top pour décaler le Drawer vers le bas */}
            <Drawer name="Logs"></Drawer>
          </div>
          {/* Contenu principal avec UpperBar à gauche */}
          <div className="flex flex-row flex-grow overflow-hidden">
            {/* UpperBar à gauche */}
            <div className="w-0/6 h-full flex-shrink-0 mt-4">
            </div>
            {/* Contenu principal centré */}
            <div className="flex flex-col w-[90%] h-full overflow-y-auto">
              <Content />
            </div>
          </div>
        </div>
      );
    }


export default AppLogs;