import { configureStore, createSlice } from "@reduxjs/toolkit";
import { situation } from "./components/conducteur/countries";

const rootSlice = createSlice({
  addReleve(state, action) {
    const newReleve = action.payload;
    state.antecedents.releves.push(newReleve);
  },
  name: "root",
  initialState: {
    pictures: {
      permisRecto: "",
      permisVerso: "",
      carteGrise: "",
      releves: [],
    },
    permis: {
      nom: "",
      prenom: "",
      civilite: "",
      birthdate: "",
      license_date: "",
      date_deffet: "",
      country: "61",
      situation: "",
      profession: "",
      type: "",
    },

    carte_grise: {
      birthday_car: "",
      fuel_type: "",
      immatriculation: "",
      purchase_date: "",
      tax_horsepower: "",
      type_mine: "",
      vehicule_assure: true,
      date_resil: "",
      tracker: false,
      financement: undefined,
      deplacement: "",
    },
    divers: {},
    bonus_05_date: "",
    antecedents: {
      global: {
        adresse: {
          numero: "",
          rue: "",
          ville: "",
          code_postal: "",
          complement: "",
        },
        bonus: {
          anciennete: "",
          value: "",
        },
        sinistres: [],
      },
      releves: [],
    },
    state: {
      tarif: {
        company: "",
        format: "",
        formule: "",
      },
    },
    signature: {
      montant: "",
      notes: "",
      besoins: "",
      reco: "",
      formule: "",
    },
    tarifs: {
      zephir: {},
      oyat: {},
      wazari: {},
      solly: {},
    },
  },
  reducers: {
    sinistreDateChanged: (state, action) => {
      console.log("Payload de date changed", action.payload)
      const { sinistreId, releveId, newValue } = action.payload;
      console.log("Payload de date changed", newValue)
      console.log("SinistreID de date changed", sinistreId)
      console.log("ReleveID de date changed", releveId)
      state.antecedents.releves[releveId].sinistres[sinistreId].date = newValue;
      /*const { sinistreId, newDate } = action.payload;
      state.sinistres.forEach((sinistre) => {
        if (sinistre.id === sinistreId) {
          sinistre.date = newDate;
        }
      });*/
    },
    sinistreResponsabiliteChanged: (state, action) => {
      console.log("Payload de reponsa changed", action.payload)
      const { sinistreId, releveId, responsabilite } = action.payload;
      console.log("Payload de responsa changed", responsabilite)
      console.log("SinistreID de responsa changed", sinistreId)
      console.log("ReleveID de responsa changed", releveId)
      state.antecedents.releves[releveId].sinistres[sinistreId].responsabilite = responsabilite;
      /*const { sinistreId, responsabilite } = action.payload;
      state.sinistres.forEach((sinistre) => {
        if (sinistre.id === sinistreId) {
          sinistre.responsabilite = responsabilite;
        }
      });*/
    },
    sinistreNatureChanged: (state, action) => {
      //CHANGER NATURE VIA Relevé
      console.log("Payload de nature changed", action.payload)
      const { sinistreId, releveId, nature } = action.payload;
      console.log("Payload de nature changed", nature)
      console.log("SinistreID de nature changed", sinistreId)
      console.log("ReleveID de nature changed", releveId)
      state.antecedents.releves[releveId].sinistres[sinistreId].nature = nature;
      /*const { sinistreId, nature } = action.payload;
      state.sinistres.forEach((sinistre) => {
        if (sinistre.id === sinistreId) {
          sinistre.nature = nature;
        }
      });*/
    },
    sinistreRemovedChanged(state, action) {
      const { releveIndex, sinistreId } = action.payload;
      const releve = state.antecedents.releves.find(
        (r) => r.id === releveIndex
      );
      if (releve) {
        releve.sinistres = releve.sinistres.filter(
          (sinistre) => sinistre.id !== sinistreId
        );
      }
    },
    addReleveEmpty: (state, action) => {
      console.log("State empty", state)
      console.log("Action empty", action)
      state.antecedents.releves.push(action.payload);
    },
    addSinistres: (state, action) => {
      state.sinistres = action.payload;
    },
    addReleve: (state, action) => {
      state.antecedents.releves.push(action.payload);
    },
    clearReleves: (state) => {
      state.antecedents.releves = [];
    },
    permisRectoAdded(state, action) {
      state.pictures.permisRecto = action.payload;
    },
    getPermisRecto(state) {
      return state.pictures.permisRecto;
    },
    permisVersoAdded(state, action) {
      state.pictures.permisVerso = action.payload;
    },
    getPermisVerso(state) {
      return state.pictures.permisVerso;
    },
    carteGriseAdded(state, action) {
      state.pictures.carteGrise = action.payload;
      console.log(action, "hello");
    },
    getCarteGrise(state) {
      return state.pictures.carteGrise;
    },
    relevePictureAdded(state, action) {
      state.pictures.releves.push(action.payload);
    },
    sinistresAdded: (state, action) => {
      console.log("C'est le payload", action.payload)
      const { sinistre, id_releve} = action.payload;
      console.log("C'est le sinistre", sinistre)
      console.log("C'ets le relevé", id_releve)
      state.antecedents.releves[id_releve].sinistres.push(sinistre)
      console.log("state après ajout relevé")
    },
    sinistreRemoved(state, action) {

      //VIA Relevé
      const { sinistreId, releveId} = action.payload;
      state.antecedents.releves[releveId].sinistres.splice(sinistreId, 1);

      console.log(
        "État global après suppression:",
        JSON.parse(JSON.stringify(state))
      );
    },
    bonusReleveAdded(state, action) {
      const pos = state.antecedents.releves
        .map((e) => e.id)
        .indexOf(action.payload.releve);
      state.antecedents.releves[pos].bonus_malus = action.payload.bonus_malus;
    },
    echeanceReleveAdded(state, action) {
      const pos = state.antecedents.releves
        .map((e) => e.id)
        .indexOf(action.payload.releve);
      state.antecedents.releves[pos].date_echeance =
        action.payload.date_echeance;
    },
    effetReleveAdded(state, action) {
      const pos = state.antecedents.releves
        .map((e) => e.id)
        .indexOf(action.payload.releve);
      state.antecedents.releves[pos].date_effet = action.payload.date_effet;
    },
    resilReleveAdded(state, action) {
      const pos = state.antecedents.releves
        .map((e) => e.id)
        .indexOf(action.payload.releve);
      state.antecedents.releves[pos].date_resiliation =
        action.payload.date_resiliation;
    },
    editionReleveAdded(state, action) {
      const pos = state.antecedents.releves
        .map((e) => e.id)
        .indexOf(action.payload.releve);
      state.antecedents.releves[pos].date_edition = action.payload.date_edition;
    },
    dateSinistreAdded(state, action) {
      const { releve, sinistre, date } = action.payload;
      const releveIndex = state.antecedents.releves.findIndex(
        (r) => r.id === releve
      );
      if (releveIndex === -1) return;
      const sinistreIndex = state.antecedents.releves[
        releveIndex
      ].sinistres.findIndex((s) => s.id === sinistre);
      if (sinistreIndex === -1) return;

      state.antecedents.releves[releveIndex].sinistres[sinistreIndex].date =
        date;
    },
    globalSinistreAdded(state, action) {
      state.antecedents.global.sinistres = action.payload;
    },
    // responsabiliteSinistreAdded(state, action) {
    //     const { releve, sinistre, responsabilite } = action.payload;
    //     const pos = state.antecedents.releves.findIndex(e => e.id === releve);
    //     if (pos === -1) return;

    //     const sin = state.antecedents.releves[pos].sinistres.findIndex(e => e.id === sinistre);
    //     if (sin === -1) return;

    //     state.antecedents.releves[pos].sinistres[sin].responsabilite = responsabilite;
    // },
    // natureSinistreAdded(state, action) {
    //     const { releve, sinistre, nature } = action.payload;
    //     const pos = state.antecedents.releves.findIndex(e => e.id === releve);
    //     if (pos === -1) return;

    //     const sin = state.antecedents.releves[pos].sinistres.findIndex(e => e.id === sinistre);
    //     if (sin === -1) return;
    //     state.antecedents.releves[pos].sinistres[sin].nature = nature;
    // },
    ancienneteAdded(state, action) {
      const pos = state.antecedents.releves
        .map((e) => e.id)
        .indexOf(action.payload.releve);
      console.log("Fonction ancienneté");
      console.log(action.payload);
      //Remettre ca a jour
      state.antecedents.releves[pos].nb_months = action.payload;
      console.log("After");
    },
    immatriculationAdded(state, action) {
      state.carte_grise.immatriculation = action.payload;
    },
    achatAdded(state, action) {
      state.carte_grise.purchase_date = action.payload;
    },
    chevauxAdded(state, action) {
      state.carte_grise.tax_horsepower = action.payload;
    },
    birthdayCarAdded(state, action) {
      state.carte_grise.birthday_car = action.payload;
    },
    vehiculeAssureAdded(state, action) {
      state.carte_grise.vehicule_assure = action.payload;
    },
    nameAdded(state, action) {
      state.permis.nom = action.payload;
    },
    firstNameAdded(state, action) {
      state.permis.prenom = action.payload;
    },
    birthdateAdded(state, action) {
      state.permis.birthdate = action.payload;
    },
    licenseDateAdded(state, action) {
      state.permis.license_date = action.payload;
    },
    paysAdded(state, action) {
      state.permis.country = action.payload;
    },
    professionAdded(state, action) {
      state.permis.profession = action.payload;
    },
    situationAdded(state, action) {
      state.permis.situation = action.payload;
    },
    civiliteAdded(state, action) {
      state.permis.civilite = action.payload;
    },
    dateEffetAdded(state, action) {
      state.permis.date_deffet = action.payload;
    },
    permisTypeAdded(state, action) { 
      console.log("Type permis" + action.payload)
      state.permis.type = action.payload;
    },
    telAdded(state, action) {
      state.divers.phone_number = action.payload;
    },
    emailAdded(state, action) {
      state.divers.email = action.payload;
    },
    adresseRueAdded(state, action) {
      state.antecedents.global.adresse.rue = action.payload;
    },
    adresseNumeroAdded(state, action) {
      state.antecedents.global.adresse.numero = action.payload;
    },
    adresseVilleAdded(state, action) {
      state.antecedents.global.adresse.ville = action.payload;
    },
    adresseComplementAdded(state, action) {
      state.antecedents.global.adresse.complement = action.payload;
    },
    adresseCodePostalAdded(state, action) {
      state.antecedents.global.adresse.code_postal = action.payload;
    },
    tarifAdded(state, action) {
      state.state.tarif = action.payload;
    },
    bonusGlobalAdded(state, action) {
      console.log("Bonus final calculé :" + action.payload);

      state.antecedents.global.bonus.value = action.payload;
    },
    ancienneteGlobalAdded(state, action) {
      state.antecedents.global.bonus.anciennete = action.payload;
    },
    montantAdded(state, action) {
      state.signature.montant = action.payload;
    },
    besoinsAdded(state, action) {
      state.signature.besoins = action.payload;
    },
    recoAdded(state, action) {
      state.signature.reco = action.payload;
    },
    notesAdded(state, action) {
      state.signature.notes = action.payload;
    },
    formuleAdded(state, action) {
      state.signature.formule = action.payload;
    },
    dateResilAdded(state, action) {
      state.carte_grise.date_resil = action.payload;
    },
    trackerAdded(state, action) {
      state.carte_grise.tracker = action.payload;
    },
    financementAdded(state, action) {
      state.carte_grise.financement = action.payload;
    },
    deplacementAdded(state, action) { 
      state.carte_grise.deplacement = action.payload;
    },
    nbMonthGlobalAdded(state, action) {
      state.antecedents.global.nb_months = action.payload;
    },
    updateMotifResiliation(state, action) {
      const { releveId, motif, resilie } = action.payload;
      state.antecedents.releves.forEach((releve) => {
        if (releve.id === releveId) {
          releve.motif_resiliation = motif;
          releve.resilie = resilie;
        }
      });
    },
    date05Added(state, action) {
      state.bonus_05_date = action.payload;
      console.log("Added date 0.50" + state.bonus_05_date);
    },
    relevesMonths(state, action) {
      const { id, months } = action.payload;
      state.antecedents.releves[id].months = months;
    },
    relevesDays(state, action) {
      const { id, days } = action.payload;
      state.antecedents.releves[id].days = days;
    },
    removeReleve(state, action) {
      console.log("Verification suppresion", state.antecedents.releves)
      console.log("Verification suppresion 2", action.payload)
      const newReleves = state.antecedents.releves.filter(
        (releve) => releve.id !== action.payload
      );
      state.antecedents.releves = newReleves;
      if (newReleves.length > 0) {
        state.antecedents.releves = newReleves.map((releve, index) => ({
          ...releve,
          id: index,
        }));
      }
    },
  },
});

export const {
  sinistresAdded,
  sinistreDateChanged,
  sinistreResponsabiliteChanged,
  sinistreNatureChanged,
  sinistreRemovedChanged,
  addSinistres,
  bonusGlobalAdded,
  dateEffetAdded,
  // responsabiliteSinistreAdded,
  // natureSinistreAdded,
  addReleve,
  permisRectoAdded,
  ancienneteGlobalAdded,
  getPermisRecto,
  permisVersoAdded,
  getPermisVerso,
  carteGriseAdded,
  relevePictureAdded,
  sinistreAdded,
  civiliteAdded,
  sinistreRemoved,
  bonusReleveAdded,
  echeanceReleveAdded,
  effetReleveAdded,
  resilReleveAdded,
  editionReleveAdded,
  dateSinistreAdded,
  immatriculationAdded,
  achatAdded,
  chevauxAdded,
  birthdayCarAdded,
  vehiculeAssureAdded,
  nameAdded,
  firstNameAdded,
  birthdateAdded,
  licenseDateAdded,
  telAdded,
  emailAdded,
  adresseRueAdded,
  adresseNumeroAdded,
  adresseVilleAdded,
  adresseCodePostalAdded,
  tarifAdded,
  montantAdded,
  besoinsAdded,
  recoAdded,
  notesAdded,
  formuleAdded,
  paysAdded,
  dateResilAdded,
  trackerAdded,
  financementAdded,
  ancienneteAdded,
  nbMonthGlobalAdded,
  globalSinistreAdded,
  updateMotifResiliation,
  date05Added,
  clearReleves,
  addReleveEmpty,
  removeReleve,
  relevesMonths,
  relevesDays,
  adresseComplementAdded,
  professionAdded,
  situationAdded,
  deplacementAdded,
  permisTypeAdded,
} = rootSlice.actions;

export default rootSlice.reducer;
