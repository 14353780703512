import { useState } from "react"
import TextInput from "../components/utils/text-input"
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast";

export default function ModPassword() {
    const [pwd, setPwd] = useState("")
    const [newPwd, setNewPwd] = useState("")
    const [newPwdConfirm, setNewPwdConfirm] = useState("")
    const navigate = useNavigate()
    const [pwdValid, setPwdValid] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    function checkNewPwd(pwd) {
        if (pwd.length <= 8)
            return "Le mot de passe doit contenir au moins 8 caracteres"
        if (! /[^A-Z]/.test(pwd))
            return "le mot de passe doit contenir au moins une majuscule"
        if (! /\d/.test(pwd))
            return "Le mot de passe doit contenir au moins un chiffre"
        if (! /[^a-zA-Z0-9 ]/.test(pwd))
            return "Le mot de passe doit contenir au moins un caractere special"
        return true
    }

    function change_pwd()
    {
        setIsLoading(true)
        fetch(
            "https://app.promo-assurance.fr/api/auth/change_pwd",
            {
              method: "POST",
              body: JSON.stringify({
                password: pwd,
                new_password: newPwd
              }),
              headers: new Headers({'content-type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem("token")}),

            }
        ).then(e => {       
            if (e.status === 401 || e.status === 403 ) {
                toast.error("Mot de passe incorrect")
                console.log("after toast")
            } else if (e.status === 200) {
                toast.success("Mot de passe modifié avec succès")
            } else {
                toast.error("Something wrong happened")
            }
            setIsLoading(false)
        })
    }
        
    return <div className="w-full h-[100vh] flex justify-center items-center">
        <div className="w-1/3 rounded-md p-4 border-gray-300 border-solid border-2 flex flex-col gap-6">
            <div className="relative">
                <TextInput label="Mot de passe"  password placeholder="Mot de passe" onChange={(e) => {setPwd(e.target.value);}} value={pwd}></TextInput>
            </div>
            <div className="relative">
                <TextInput classname={pwdValid === true ? "" : "border-red-500"} label="Nouveau mot de passe" password placeholder="Nouveau mot de passe" onChange={(e) => {setNewPwd(e.target.value); setPwdValid(checkNewPwd(e.target.value))}} value={newPwd}></TextInput>
                <p className="absolute text-xs text-red-500">{pwdValid !== true ? pwdValid : ""}</p>
            </div>
            <div className="relative">
                <TextInput label="Confirmation du nouveau mot de passe"  password placeholder="Confirmation du nouveau mot de passe" onChange={(e) => {setNewPwdConfirm(e.target.value);}} value={newPwdConfirm}></TextInput>
                <p className="absolute text-xs text-red-500">{newPwdConfirm !== newPwd ? "Les deux mots de passe ne correspondent pas" : ""}</p>
            </div>
            <div className="w-full flex justify-center">
                <button
                    disabled={isLoading || newPwdConfirm !== newPwd}
                    className={
                        newPwdConfirm === newPwd && pwd.length !== 0 && pwdValid === true && isLoading === false ?
                        "flex flex-row bg-promo-green w-1/2 py-3 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105":
                        "flex flex-row bg-promo-grey-text w-1/2 py-3 text-white items-center justify-center font-semibold relative  rounded-full shadow-lg transition-all duration-300 ease-in-out transform"
                    } 
                    onClick={() => {
                        change_pwd()
                    }}>
                        Modifier le mot de passe
                </button>
            </div>

            <button
                className="absolute bg-promo-green w-1/6 py-3 text-white items-center justify-center font-semibold hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 top-5 left-5"
                onClick={() => {
                navigate('/profile')
                }}>
            {"<-"} Retour
        </button>
        </div>
    </div>
}