import React from "react";
import logo from "../../assets/Logo.png";
import DrawerTop from "./drawer-top";
import DrawerStepDescription from "./drawer-step-description";
import { useNavigate } from "react-router-dom";

export default function Drawer(props) {
  const navigate = useNavigate()
  return (
    <></>
    // <div className="w-full h-24 bg-white flex items-center justify-between px-6 py-4 relative border-b border-gray-200 shadow-sm">

    //   {/* Description au centre */}
    //   <div className="flex-grow flex flex-col justify-center items-center">
    //     <DrawerStepDescription
    //       step={props.step}
    //       name={props.name}
    //     />
    //   </div>

    //   {/* Progression des étapes à droite */}
    //   {/* <div className="flex justify-end items-center">
    //     <DrawerTop step={props.step} allsteps={props.allsteps} />
    //   </div> */}


    // </div>
  );
}
