import React, { useEffect, useState } from "react";
import TextInput from "../utils/text-input";
import DateInput from "../utils/date-input";
import { useNavigate } from "react-router-dom";
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from "react-icons/bs";
import SelectInput from "../utils/select-input";
import { BiSolidPhone } from "react-icons/bi";
import { HiMail } from "react-icons/hi";
import { addDays } from 'date-fns';
import { countries, profession, civilite, situation, EU_COUNTRIES } from "./countries";
import {
  nameAdded,
  firstNameAdded,
  birthdateAdded,
  licenseDateAdded,
  telAdded,
  emailAdded,
  adresseRueAdded,
  civiliteAdded,
  adresseNumeroAdded,
  adresseVilleAdded,
  adresseCodePostalAdded,
  paysAdded,
  dateEffetAdded,
  adresseComplementAdded,
  professionAdded,
  situationAdded,
  permisTypeAdded,
} from "../../root-reducer";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

function Content(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permis = useSelector((state) => state.root.permis);
  const dateNaissance = permis.birthdate.split("/");
  const dateObtentionPermis = permis.license_date.split("/");
  let ageLorsDeLObtention = dateObtentionPermis[2] - dateNaissance[2];
  const m = dateObtentionPermis[1] - dateNaissance[1];
  const d = dateObtentionPermis[0] - dateNaissance[0];
  const divers = useSelector((state) => state.root.divers);
  const adresse = useSelector((state) => state.root.antecedents.global.adresse);
  const today = new Date();
  const [formattedDate, setFormattedDate] = useState(
    `${today.getDate().toString().padStart(2, "0")}/${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${today.getFullYear()}`
  );
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  let date_obtention = permis.license_date;
  const parts = date_obtention.split("/");
  let year = parseInt(parts[2]);

  useEffect(() => {
    if (parts[2] && parts[2].length === 2) {
      const currentYearTwoDigits = today.getFullYear() % 100;
      if (year >= currentYearTwoDigits) {
        year = 1900 + year;
      } else {
        year = 2000 + year;
      }
      date_obtention = `${parts[0]}/${parts[1]}/${year}`;
      dispatch(licenseDateAdded(date_obtention));
    }
  }, [dispatch, parts, today]);

  const validateEmail = (email) => {
    if (!email || email.trim().length === 0) {
      return false;
    }
    const re =
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return re.test(String(email).toLowerCase());
  };
  
  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.trim().length === 0) {
      return false;
    }
    const re = 
      /^(06|07)\d{8}$/;
    return re.test(phoneNumber);
  };

  const formatInput = (input) => {
    if (!input) return '';
    return input.replace(/\b\w/g, char => char.toUpperCase());
  };

  const validateForm = () => {
    if (permis.nom === "") {
      return false;
    }
    if (permis.prenom === "") {
      return false;
    }
    if (permis.birthdate === "") {
      return false;
    }
    if (permis.license_date === "") {
      return false;
    }
    if (adresse.rue === "") {
      return false;
    }
    if (adresse.code_postal === "") {
      return false;
    }
    if (adresse.ville === "") {
      return false;
    }
    if (divers.phone_number === "" || !divers.phone_number) {
      return false;
    }
    if (divers.email === "" || !divers.email) {
      return false;
    }
    if (permis.date_deffet === "") {
      return false;
    }
    if (!validatePhoneNumber(divers.phone_number)) {
      return false;
    }
    if (!validateEmail(divers.email)) {
      return false;
    }
    if (ageLorsDeLObtention < 16) {
      return false;
    }
    return true;
  };  

  function handleNext() {
    if (!validatePhoneNumber(divers.phone_number)) {
      toast.error(
        "Le numéro de téléphone n'est pas au bon format. Veuillez saisir un numéro de téléphone valide."
      );
      return;
    }
    if (!validateEmail(divers.email)) {
      toast.error("L'adresse email n'est pas au bon format. Veuillez saisir une adresse email valide.");
      return;
    }
    if (!permis.birthdate || !permis.license_date) {
      toast.error(
        "Les informations de date de naissance ou de date d'obtention du permis sont manquantes."
      );
      return;
    }
    const dateNaissance = permis.birthdate.split("/");
    const dateObtentionPermis = permis.license_date.split("/");
    let ageLorsDeLObtention = dateObtentionPermis[2] - dateNaissance[2];
    const m = dateObtentionPermis[1] - dateNaissance[1];
    const d = dateObtentionPermis[0] - dateNaissance[0];
    if (m < 0 || (m === 0 && d < 0)) {
      ageLorsDeLObtention--;
    }
    if (ageLorsDeLObtention < 16) {
      toast.error(
        "Vous ne pouvez pas avoir obtenu votre permis avant l'âge de 16 ans."
      );
      return;
    }
    if (permis.nom === "") {
      toast.error("Veuillez saisir le nom de famille.");
    } else if (permis.prenom === "") {
      toast.error("Veuillez sasir le prénom.");
    } else if (permis.birthdate === "") {
      toast.error("Veuillez saisir la date de naissance.");
    } else if (permis.license_date === "") {
      toast.error("Veuillez saisir la date d'obtention du permis de conduire.");
    } else if (adresse.rue === "") {
      toast.error("Veuillez saisir la rue.");
    } else if (adresse.code_postal === "") {
      toast.error("Veuillez saisir le code postal.");
    } else if (adresse.ville === "") {
      toast.error("Veuillez saisir la ville.");
    } else if (divers.phone_number === "" || !divers.phone_number) {
      toast.error("Veuillez saisir le numéro de téléphone.");
    } else if (divers.email === "" || !divers.email) {
      toast.error("Veuillez saisir l'adresse email.");
    } else if (permis.date_deffet === "") {
      toast.error("Veuillez saisir la date d'effet.");
    } else {
      navigate("/vehicule");
    }
  }
  const permisTypeOptions = [
    { value: "Permis B", label: "Permis B" }
    //{ value: "Permis étranger UE", label: "Permis étranger UE" },
    //{ value: "Permis étranger hors UE", label: "Permis étranger hors UE" }
  ];
  const handleAddContact = async () => {
    const contactData = {
      email: divers.email,
      firstname: permis.prenom,
      lastname: permis.nom,
      phone: divers.phone_number,
      address: adresse.rue,
      city: adresse.ville,
      zip: adresse.code_postal,
    };

    try {
      const response = await fetch('https://app.promo-assurance.fr/api/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contactData),
      });

      if (response.ok) {
        toast.success("Contact ajouté avec succès à HubSpot !");
      } else {
        toast.error("Échec de l'ajout du contact à HubSpot.");
      }
    } catch (error) {
      toast.error("Une erreur s'est produite lors de l'ajout du contact.");
    }
  };
  //Le texte en hidden c'est pour occuper la même place que sur la page principal et garder le "carré" de la page principal pour contenir les infos
  //On essaye de garde de format "carré" de la page principal avec les documents pour homogénéisé le tout
  return (
    <div className=" h-5/6 flex flex-col items-center justify-center relative">
            <div className="text-2xl font-semibold text-promo-text my-6" style={{ visibility: "hidden" }}>
        Importez vos documents ou passez directement à l'étape suivante
      </div>
      <div className="w-[40%] flex flex-col gap-4 my-8 h-5/6">
        <div className="flex flex-row gap-8 w-full justify-between">
          <TextInput
            placeholder={"Nom"}
            label={"Nom"}
            classname={`border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105`}
            value={permis.nom}
            onChange={(e) => {
              dispatch(nameAdded(formatInput(e.target.value)));
            }}
          ></TextInput>
          <TextInput
            placeholder={"Prénom"}
            label={"Prénom"}
            classname={`border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105`}
            value={permis.prenom}
            onChange={(e) => {
              dispatch(firstNameAdded(formatInput(e.target.value)));
            }}
          ></TextInput>
        </div>
        <div className="flex flex-row gap-8 w-full justify-between">
          <TextInput
            placeholder={"Adresse"}
            label={"Adresse"}
            classname={`border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105`}
            value={adresse.rue}
            onChange={(e) => {
              dispatch(adresseRueAdded(formatInput(e.target.value)));
            }}
          ></TextInput>
        </div>
        <div className="flex flex-row gap-8 w-full justify-between">
          <TextInput
            placeholder={"Code postal"}
            label={"Code postal"}
            classname={`border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105`}
            value={adresse.code_postal}
            maxLength={5}
            onChange={(e) => {
              dispatch(adresseCodePostalAdded(formatInput(e.target.value)));
            }}
          ></TextInput>
          <TextInput
            placeholder={"Ville"}
            label={"Ville"}
            classname={`border border-gray-300 focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-blue-500 transition-transform duration-200 ease-in-out transform focus:scale-105`}
            value={adresse.ville}
            onChange={(e) => {
              dispatch(adresseVilleAdded(formatInput(e.target.value)));
            }}
          ></TextInput>
        </div>
        <div className="flex flex-row gap-8 w-full justify-between">
          <DateInput
            placeholder={"Date de naissance"}
            label={"Date de naissance"}
            value={permis.birthdate}
            onChange={(e) => {
              dispatch(birthdateAdded(e));
            }}
          ></DateInput>
                  <DateInput
            placeholder={"Date d'obtention"}
            label={"Date d'obtention"}
            value={permis.license_date}
            onChange={(e) => {
              dispatch(licenseDateAdded(e));
            }}
          ></DateInput>
        </div>
        <div className={`flex flex-row gap-8 justify-between`}>
          <TextInput
            placeholder={"No de téléphone"}
            label={"No de téléphone"}
            icon={BiSolidPhone}
            classname={`border ${isPhoneNumberValid ? 'border-gray-300' : 'border-red-500'} focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-${isPhoneNumberValid ? 'blue-500' : 'red-500'} transition-transform duration-200 ease-in-out transform focus:scale-105`}
            value={divers.phone_number}
            maxLength={10}
            onChange={(e) => {
              const phoneNumber = e.target.value;
              setIsPhoneNumberValid(validatePhoneNumber(phoneNumber));
              dispatch(telAdded(phoneNumber));
            }}
          ></TextInput>
          <TextInput
            placeholder={"E-mail"}
            label={"E-mail"}
            icon={HiMail}
            classname={`border ${isEmailValid ? 'border-gray-300' : 'border-red-500'} focus-visible:outline-none px-6 placeholder:italic placeholder:text-gray-400 rounded-lg focus:ring-2 focus:ring-${isEmailValid ? 'blue-500' : 'red-500'} transition-transform duration-200 ease-in-out transform focus:scale-105`}
            value={divers.email}
            onChange={(e) => {
              const email = e.target.value;
              setIsEmailValid(validateEmail(email));
              dispatch(emailAdded(email));
            }}
          ></TextInput>
        </div>
      </div>
      <button
            onClick={handleAddContact}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            <div>Ajouter contact hubspot</div>
          </button>
          <div className="flex flex-row w-full items-center justify-center fixed bottom-5 left-0 right-0">
  <button
    className="flex flex-row bg-promo-green w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
    onClick={() => {
      navigate("/docs");
    }}
  >
    <BsArrowLeftCircleFill size={25} className="absolute left-5" />
    <div>ETAPE PRECEDENTE</div>
  </button>

  {validateForm() ? (
    <button
      className="flex flex-row bg-promo-green w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
      onClick={() => {
        handleNext();
        navigate("/vehicule");
      }}
    >
      <div>ETAPE SUIVANTE</div>
      <BsArrowRightCircleFill size={25} className="absolute right-5" />
    </button>
  ) : null}

  {!validateForm() ? (
    <button
      className="flex flex-row bg-gray-500 w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative rounded-full shadow-lg transition-all duration-300 ease-in-out transform"
      onClick={() => {
        handleNext();
      }}
    >
      <div>ETAPE SUIVANTE</div>
      <BsArrowRightCircleFill size={25} className="absolute right-5" />
    </button>
  ) : null}
</div>

    </div>
  );
}

export default Content;
