import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import OyatLogo from '../../assets/OyatLogo.png'
import ZephirLogo from '../../assets/zephirlogo.png'
import LuxiorLogo from '../../assets/LuxiorLogo.png'
import AprilLogo from '../../assets/AprilLogo.png'
import SollyLogo from '../../assets/sollylogo.png'
import { checkLogin } from "../utils/check-login";
import WazariLogo from '../../assets/WAZARI.jpg'
import { parse, differenceInMonths, differenceInDays } from 'date-fns';
import { Tooltip } from "react-tooltip";
import { CiCircleInfo } from "react-icons/ci";


const PriceComparison = () => {
  const [prices, setPrices] = useState({});
  const [url_devis, setUrld] = useState(null)
  const currentURL = window.location.href;
  const [zephirLink, setZephirLink] = useState(null);
  const [sollyLink, setSollyLink] = useState(null); 
  const [wazariLink, setWazariLink] = useState(null); 

  const data_filled = useSelector(state => state)
  const r = useSelector(state => state.root.antecedents.releves)

  console.log("crash here 1")
  const calculateMonthsForReleve = (releve) => {
      if (!releve.date_effet) return 0;

      let effet = parse(releve.date_effet, "dd/MM/yyyy", new Date());
      let resil = releve.date_resiliation ? parse(releve.date_resiliation, "dd/MM/yyyy", new Date()) : new Date();

      let monthDelta = differenceInMonths(resil, effet);
      let dayDelta = differenceInDays(resil, effet) % 30;

      let totalMonths = monthDelta + Math.floor(dayDelta / 30);
      console.log("Total months", totalMonths);
      return totalMonths >= 0 ? totalMonths : 0;
  };

  const antecedents_assurance_treated = data_filled.root.antecedents.releves.map(releve => calculateMonthsForReleve(releve))[0]
  console.log("This is the antecedents", antecedents_assurance_treated)
  /* Traitement des infos de oyat */
  function getTodayDate() {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, '0');
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const year = currentDate.getFullYear();
      return `${day}/${month}/${year}`;
  }
  var lastIndex = r.length - 1;
  function filterReleves(releves, referenceDate) {
      console.log("DATE REF", referenceDate)
      const [day, month, year] = referenceDate.split('/');
      const today = new Date(`${year}-${month}-${day}`)
      const thirtySixMonthsAgo = new Date(today.setMonth(today.getMonth() - 36));
  
      return releves.filter(releve => {
          if (releve.date_resiliation) {
              const dateResiliation = new Date(releve.date_resiliation.split('/').reverse().join('-'));
              return dateResiliation > thirtySixMonthsAgo;
          }
          return true;
      });
  }
  let referenceDate;
  if (data_filled.root.permis.date_deffet) {
      referenceDate = data_filled.root.permis.date_deffet;
  } else {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = today.getFullYear();
      referenceDate = `${day}/${month}/${year}`;
  }
  let filteredReleves = filterReleves(r, referenceDate);
  //Relevé résiliation / sinistres +36 mois ne pas compte
  const data_oyat = {
      data: {
          antecedents: {
            global: {
              adresse: data_filled.root.antecedents.global.adresse, /* Rempli toutes les infos d'adresse */
              antecedents_assurance: antecedents_assurance_treated !== undefined ? antecedents_assurance_treated : 0, /* antecedents assurance */
              bonus: {
                anciennete: data_filled.root.antecedents.global.bonus.anciennete,
                depuis_si_50: "", /*???*/
                date: {
                  day: "",
                  month: "",
                  year: ""
                },
                value: data_filled.root.antecedents.global.bonus.value,
              },
              resiliations: {   /*??? resiliation */
                aggravation_de_risques: "0",
                autre: "0",
                fraude_nullite: "0",
                resilie_depuis_3ans: "0",
                resilie_echeance: "0",
                resilie_non_paiement: "0"
              },
              sinistres: r && r.length > 0 ? r.map(item => item.sinistres).flat() : undefined
            },
            releves: filteredReleves.length > 0 ? filteredReleves : undefined,
          },
          date_bonus_05: data_filled.root.bonus_05_date,
          carte_grise: {
            birthday_car: data_filled.root.carte_grise.birthday_car,
            fuel_type: data_filled.root.carte_grise.fuel_type,
            immatriculation: data_filled.root.carte_grise.immatriculation,
            purchase_date: data_filled.root.carte_grise.purchase_date,
            tax_horsepower: data_filled.root.carte_grise.tax_horsepower,
            type_mine: data_filled.root.carte_grise.type_mine,
            vehicule_assure: data_filled.root.carte_grise.vehicule_assure,
            date_vehicule_assure: data_filled.root.carte_grise.date_resil

          },
          divers: {
            email: data_filled.root.divers.email,
            phone_number: data_filled.root.divers.phone_number,
            financement: "comptant",
            organisme_preteur: "Vivacar",
            date_deffet: data_filled.root.permis.date_deffet || getTodayDate(),
          },
          permis: {
            birthdate: data_filled.root.permis.birthdate,
            first_name: data_filled.root.permis.prenom,
            license_date: data_filled.root.permis.license_date,
            name: data_filled.root.permis.nom,
            country: data_filled.root.permis.country
          }
        }
      };
  /* Requete pour aller chercher les tarifs d'oyat */
  if (data_oyat.data.antecedents.global.sinistres && data_oyat.data.antecedents.global.sinistres.length > 0) {
      const firstDate = new Date(data_oyat.data.antecedents.global.sinistres[0].date);
      const secondDate = new Date(data_oyat.data.divers.date_deffet);
      const monthsDifference = (secondDate.getFullYear() - firstDate.getFullYear()) * 12 + 
                              (secondDate.getMonth() - firstDate.getMonth());

      if (monthsDifference >= 36) {
          data_oyat.data.antecedents.global.sinistres = undefined; // Remove
      }
  }
  const navigate = useNavigate();

  function onClickSuivant() {
    toast.error("Veuillez choisir un tarif");
  }
  useEffect(() => {
    console.log("Use effect ")
      checkLogin().then( () => {
        handleRequestOyat();
        handleRequestZephir();
        handleRequestLuxior();
        handleRequestApril();
        handleRequestWazari();
        handleRequestSolly();
      })      
}, []);
const handleRequestOyat = async () => {
        console.log("Data oyat" + JSON.stringify(data_oyat, null, 2))
            try {
                const response = await fetch("https://app.promo-assurance.fr/api/oyat", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(data_oyat),
                });
                if (response.ok) {
                    const responseData = await response.json();
                    console.log("Response data", responseData);
                    let url_devis = responseData.url_devis || "";
                    let resultValue = "";
                    let resultSerenitef1 = "";
                    let resultLuxef1 = "";
                    if (responseData.prices && responseData.prices.f1) {
                      resultValue = responseData.prices.f1[0][1].split('\n')[0] + " €";
                    }
                    if (responseData.prices && responseData.prices.f2) {
                      resultSerenitef1 = responseData.prices.f2[0][1].split('\n')[0] + " €";
                    }
                    if (responseData.prices && responseData.prices.f3) {
                      resultLuxef1 = responseData.prices.f3[0][1].split('\n')[0] + " €";
                    }
                    setPrices(prevPrices => ({
                      ...prevPrices,
                      Oyat: { cheap: resultValue || "X", medium: resultSerenitef1 || "X", expensive: resultLuxef1 || "X"}
                    }));
                } else {
                    const errorData = await response.json();
                    console.error("Erreur de réponse:", errorData);
                    toast.error("Erreur lors de l'envoi des documents. Veuillez réessayer.");
                }
            } catch (error) {
                console.error("Erreur lors de la connexion au serveur:", error);
                toast.error(`Problème de connexion au serveur. Détails: ${error.message}`);
            }
    }
    const handleRequestZephir = async () => {
      console.log("Data zephir" + JSON.stringify(data_oyat, null, 2))
      try {
          const response = await fetch("https://app.promo-assurance.fr/api/zephir", {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token')
              },
              body: JSON.stringify(data_oyat),
          });
          if (response.ok) {
              const responseData = await response.json();
              console.log("Response data", responseData);
              if (responseData["erreur"]) {
                setPrices(prevPrices => ({
                  ...prevPrices,
                  Zephir: { cheap: "X", medium: "X", expensive: "X"}
                }));
              }
              else {
                  if (
                      responseData["link"] &&
                      responseData.link !== "X"
                    ) {
                      setZephirLink(responseData.link); // Store the link in state
                    }
                  let resultValue = "";
                  let resultValue2 = "";
                  let resultValue3 = "";
                  let resultSerenitef1 = "";
                  let resultSerenitef2 = "";
                  let resultSerenitef3 = "";

                  resultValue = responseData["Go"][0];
                  resultValue2 = responseData["Go"][1];
                  resultValue3 = responseData["Go"][2];
                  resultSerenitef1 = responseData["Solutio"][0];
                  resultSerenitef2 = responseData["Solutio"][1];
                  resultSerenitef3 = responseData["Solutio"][2];
                  if(resultValue == "") {
                    setPrices(prevPrices => ({
                      ...prevPrices,
                        Zephir: { cheap: resultSerenitef1 || "X", medium: resultSerenitef2 || "X", expensive: resultSerenitef3 || "X"}
                      }));
                  }
                  else {
                    setPrices(prevPrices => ({
                      ...prevPrices,
                      Zephir: { cheap: resultValue || "X", medium: resultValue2 || "X", expensive: resultValue3 || "X"}
                    }));
                  }
              }
          } else {
              const errorData = await response.json();
              console.error("Erreur de réponse:", errorData);
              toast.error("Erreur lors de l'envoi des documents. Veuillez réessayer.");
          }
      } catch (error) {
          console.error("Erreur lors de la connexion au serveur:", error);
          toast.error(`Problème de connexion au serveur. Détails: ${error.message}`);
      }
  }
  const handleRequestSolly = async () => {
    console.log("Data solly" + JSON.stringify(data_oyat, null, 2))
    try {
        const response = await fetch("https://app.promo-assurance.fr/api/sollyazar", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(data_oyat),
            });
            if (response.ok) {
                const responseData = await response.json();
                console.log("Response data", responseData);
                if (responseData["erreur"]) {
                  setPrices(prevPrices => ({
                    ...prevPrices,
                    Solly: { cheap: "X", medium: "X", expensive: "X"}
                  }));
                }
                else if (responseData && responseData["SollyAzar"]) {
                    const sollyAzarData = responseData["SollyAzar"];
                    if (
                        responseData["link"] &&
                        responseData.link !== "X"
                      ) {
                        setSollyLink(responseData.link); // Store the link in state
                      }
                      //Juste pr décaler, au cas ou le premier prix est vide
                      const isCheapPricePresent = sollyAzarData[0]?.[1] !== undefined;
                      const removeMoisFormat = (price) => {
                        if (typeof price === "string" && price.includes("/ mois")) {
                          return price.replace("/ mois", ""); // Remove "/ mois"
                        } else {
                          return price ? `${price} €` : "X"; // Add "€" or return "X"
                        }
                      };
                      const updatedPrices = isCheapPricePresent
                      ? {
                          cheap: removeMoisFormat(sollyAzarData[0]?.[1]),
                          medium: removeMoisFormat(sollyAzarData[1]?.[1]),
                          expensive: removeMoisFormat(sollyAzarData[2]?.[1]),
                        }
                      : {
                          cheap: removeMoisFormat(sollyAzarData[1]?.[1]),
                          medium: removeMoisFormat(sollyAzarData[2]?.[1]),
                          expensive: removeMoisFormat(sollyAzarData[0]?.[1]),
                        };  
                      setPrices(prevPrices => ({
                        ...prevPrices,
                        Solly: updatedPrices
                      }));
                } else {
                    console.error("responseData or responseData['SollyAzar'] is undefined.");
                }

            } else {
                const errorData = await response.json();
                console.error("Erreur de réponse:", errorData);
                toast.error("Erreur lors de l'envoi des documents. Veuillez réessayer.");
            }
        } catch (error) {
            console.error("Erreur lors de la connexion au serveur:", error);
            toast.error(`Problème de connexion au serveur. Détails: ${error.message}`);
        }
}
const handleRequestLuxior = async () => {
  console.log("Data solly" + JSON.stringify(data_oyat, null, 2))
  try {
      const response = await fetch("https://app.promo-assurance.fr/api/luxior", {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token')
              },
              body: JSON.stringify(data_oyat),
          });
          if (response.ok) {
              const responseData = await response.json();
              console.log("Response data", responseData);
              if(responseData["immat_replaced"] == 1) {
                console.error("Erreur d'immatriculation : Remplacement par AA-123-BN")
                toast.error("Erreur d'immatriculation : Remplacement par AA-123-BN")
              }
              setPrices(prevPrices => ({
                      ...prevPrices,
                      Luxior: { cheap: responseData["prices"][0] || "X", medium: responseData["prices"][1] || "X", expensive: responseData["prices"][2] || "X", immat_replaced: responseData.immat_replaced}
                    }));
          } else {
              const errorData = await response.json();
              console.error("Erreur de réponse:", errorData);
              toast.error("Erreur lors de l'envoi des documents. Veuillez réessayer.");
          }
      } catch (error) {
          console.error("Erreur lors de la connexion au serveur:", error);
          toast.error(`Problème de connexion au serveur. Détails: ${error.message}`);
      }
}
const handleRequestWazari = async () => {
  console.log("Data wazari" + JSON.stringify(data_oyat, null, 2));
  try {
      const response = await fetch("https://app.promo-assurance.fr/api/wazari", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data_oyat),
      }
    );
    console.log(response)
    if (response.ok) {
      const responseData = await response.json();
      if (responseData["erreur"]) {
        setPrices(prevPrices => ({
          ...prevPrices,
          Wazari: { cheap: "Etude devis nécessaire", medium: "Etude devis nécessaire", expensive: "Etude devis nécessaire"}
        }));
      }
      else if (responseData["tarif_impossible"]) {
        setPrices(prevPrices => ({
          ...prevPrices,
          Wazari: { cheap: "X", medium: "X", expensive: "X"}
        }));
      } else {
        console.log("Response data", responseData);
        setPrices(prevPrices => ({
          ...prevPrices,
          Wazari: {
          cheap: responseData["prices"][0].replace("/MOIS", ""),
          medium: responseData["prices"][1].replace("/MOIS", ""),
          expensive: responseData["prices"][2].replace("/MOIS", ""),
          }
        }));

        console.log("=================================================");
      }
    } else {
      const errorData = await response.json();
      console.error("Erreur de réponse:", errorData);
      toast.error(
        "Erreur lors de l'envoi des documents. Veuillez réessayer."
      );
    }
  } catch (error) {
    console.error("Erreur lors de la connexion au serveur:", error);
    toast.error(
      `Problème de connexion au serveur. Détails: ${error.message}`
    );
  }
};
const handleRequestApril = async () => {
  try {
      const response = await fetch("https://app.promo-assurance.fr/api/april", {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token')
              },
              body: JSON.stringify(data_oyat),
          });
          if (response.ok) {
              const responseData = await response.json();
              setPrices(prevPrices => ({
                ...prevPrices,
                April: { 
                  cheap: responseData[0].replace(',', '.') || "X",
                  medium: responseData[1].replace(',', '.') || "X", 
                  expensive: responseData[2].replace(',', '.') || "X"
                }
              }));
          } else {
              const errorData = await response.json();
              console.error("Erreur de réponse:", errorData);
              toast.error("Erreur lors de l'envoi des documents. Veuillez réessayer.");
          }
      } catch (error) {
          console.error("Erreur lors de la connexion au serveur:", error);
          toast.error(`Problème de connexion au serveur. Détails: ${error.message}`);
      }
}
  const companies = ["Zephir", "Oyat", "Wazari", "Solly", "Luxior", "April"];
  const companyLogos = {
    Oyat: OyatLogo,
    Zephir: ZephirLogo,
    Wazari: WazariLogo,
    Solly: SollyLogo,
    Luxior: LuxiorLogo,
    April: AprilLogo
    // Add other company logos here...
  };
  const companyLink = {
    "Oyat": "https://connexion.oyat-assurances.fr/",
    "Zephir": "https://zeole.zephir.fr/authentification/login",
    "Wazari": "https://courtier.wazari.fr/#/login",
    "Solly": "https://www.sollyazarpro.com/",
    "Luxior": "https://www.luxior.fr//index.aspx",
    "April": "https://www.april-on.fr/fr/login"
  }
  //Chat GPT pour le CSS qui fait le tableau en dessous tout ce qui est grid container, mais pas le loading
  return (
<div className="w-full flex flex-col h-full">
<style>{`
    .loader {
        height: 15px;
        aspect-ratio: 5;
        margin: 0 auto;
        -webkit-mask: linear-gradient(90deg, #0000, #000 20% 80%, #0000);
        background: radial-gradient(closest-side at 37.5% 50%, #000 94%, #0000) 0/calc(80%/3) 100%;
        animation: l48 .75s infinite linear;
    }
    @keyframes l48 {
        100% { background-position: 36.36% }
    }
    .grid-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 0;
        border-collapse: collapse;
    }
    .grid-item {
        border-left: 1px solid #ddd; /* Light gray border for cells */
        border-right: 1px solid #ddd; /* Light gray border for cells */
        padding: 8px; /* Optional: adjust padding as needed */
        box-sizing: border-box; /* Ensure padding doesn't affect width/height */
        display: flex;
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
    }
    .grid-container > :nth-child(5n+1) {
        border-left: none; /* Remove left border for the first column */
    }
    .grid-container > :nth-child(-n+5) {
        border-top: none; /* Remove top border for the first row */
    }
    .grid-container > :nth-last-child(-n+5) {
        border-bottom: none; /* Remove bottom border for the last row */
    }
    .grid-container > :nth-child(5n) {
        border-right: none; /* Remove right border for the last column */
    }
    .grid-container > :nth-child(n+6):nth-child(-n+10) {
        border-top: 1px solid #ddd; /* Add top border for the second row */
    }
`}</style>
<div className="flex justify-center items-center flex-col gap-2 p-8">
<div className="text-2xl font-semibold text-promo-text my-6" style={{ visibility: "hidden" }}>
        Importez vos documents ou passez directement à l'étape suivante
      </div>
    {/* Grid for companies, prices, and buttons */}
    <div className="grid-container">
        {/* Price headers */}
        <div className="grid-item"></div>
        <div className="grid-item font-semibold text-center text-xl">Tiers</div>
        <div className="grid-item font-semibold text-center text-xl">Tiers +</div>
        <div className="grid-item font-semibold text-center text-xl">Tous risques</div>
        <div className="grid-item"></div>

        {companies.map((company) => (
            <React.Fragment key={company}>
                <div className="grid-item flex flex-col items-center">
                    <img
                        src={companyLogos[company]}
                        alt={`${company} logo`}
                        className="h-16 w-auto"
                    />
                </div>

                <div className="grid-item">
                    {prices[company]?.cheap || <div className="loader"></div>}
                </div>
                <div className="grid-item">
                    {prices[company]?.medium || <div className="loader"></div>}
                </div>
                <div className="grid-item">
                    {prices[company]?.expensive || <div className="loader"></div>}
                </div>

                <div className="grid-item flex items-center justify-center relative">
                    {prices[company]?.immat_replaced || company === "Solly" ? <div  className="absolute left-6">
                      <Tooltip id={"my-tooltip-" + company}/>
                      <CiCircleInfo  color="red" size={25} strokeWidth={0.75} data-tooltip-id={"my-tooltip-" + company} data-tooltip-content={"La plaque d'immatriculation n'est pas connue de " + company}></CiCircleInfo >
                    </div> : <></>}
                    <button className="bg-blue-500 text-white py-2 px-6 rounded-full hover:bg-blue-600" onClick={() => window.open(companyLink[company], '_blank')}>
                        Devis
                    </button>
                </div>
            </React.Fragment>
        ))}
    </div>
</div>


      {/* Navigation buttons */}
      <div className="flex flex-row w-full justify-center fixed bottom-4 left-0 right-0">
  <button
    className="flex flex-row bg-promo-green w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
    onClick={() => navigate("/releves")}
  >
    <BsArrowLeftCircleFill size={25} className="absolute left-5" />
    <div>ETAPE PRECEDENTE</div>
  </button>
  <button
    className="flex flex-row bg-promo-green w-[17%] py-4 mx-2 text-white items-center justify-center font-semibold relative hover:bg-promo-dark-green rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
    onClick={() => navigate("/releves")} style={{ visibility: "hidden" }}
  >
    <div>ETAPE SUIVANTE</div>
    <BsArrowRightCircleFill size={25} className="absolute right-5" />
  </button>
</div>

    </div>
  );
};

function Content(props) {

  return (
    <div className="w-full flex flex-col h-full">
      {/* Include the PriceComparison component here */}
      <PriceComparison />

      {/* Additional content can be added here */}
    </div>
  );
}

export default Content;
