export const countries = [
    {value: "61", label: "France"},
]

export const profession = [
    {value: "salarie_sedentaire", label: "Salarié sédentaire"},
]
/*

export const countries = [

    {value: "61", label: "France"},
    {value: "191", label: "Afghanistan"},
    {value: "192", label: "Afrique du Sud"},
    {value: "193", label: "Albanie"},
    {value: "194", label: "Algérie"},
    {value: "195", label: "Allemagne"},
    {value: "196", label: "Andorre"},
    {value: "197", label: "Angola"},
    {value: "198", label: "Antigua-et-Barbuda"},
    {value: "199", label: "Arabie saoudite"},
    {value: "200", label: "Argentine"},
    {value: "201", label: "Arménie"},
    {value: "202", label: "Australie"},
    {value: "203", label: "Autriche"},
    {value: "204", label: "Azerbaïdjan"},
    {value: "205", label: "Bahamas"},
    {value: "206", label: "Bahreïn"},
    {value: "207", label: "Bangladesh"},
    {value: "208", label: "Barbade"},
    {value: "209", label: "Belau"},
    {value: "210", label: "Belgique"},
    {value: "211", label: "Belize"},
    {value: "212", label: "Bénin"},
    {value: "213", label: "Bhoutan"},
    {value: "214", label: "Biélorussie"},
    {value: "215", label: "Birmanie"},
    {value: "216", label: "Bolivie"},
    {value: "217", label: "Bosnie-Herzégovine"},
    {value: "218", label: "Botswana"},
    {value: "219", label: "Brésil"},
    {value: "220", label: "Brunei"},
    {value: "221", label: "Bulgarie"},
    {value: "222", label: "Burkina"},
    {value: "29", label: "Burundi"},
    {value: "30", label: "Cambodge"},
    {value: "31", label: "Cameroun"},
    {value: "32", label: "Canada"},
    {value: "33", label: "Cap-Vert"},
    {value: "34", label: "Chili"},
    {value: "35", label: "Chine"},
    {value: "36", label: "Chypre"},
    {value: "37", label: "Colombie"},
    {value: "38", label: "Comores"},
    {value: "39", label: "Congo"},
    {value: "40", label: "Congo (la Rép. dém. du)"},
    {value: "41", label: "Cook (les Îles)"},
    {value: "42", label: "Corée du Nord"},
    {value: "43", label: "Corée du Sud"},
    {value: "44", label: "Costa Rica"},
    {value: "45", label: "Côte d'Ivoire"},
    {value: "46", label: "Croatie"},
    {value: "47", label: "Cuba"},
    {value: "48", label: "Danemark"},
    {value: "49", label: "Djibouti"},
    {value: "50", label: "Dominique"},
    {value: "51", label: "Égypte"},
    {value: "52", label: "Émirats arabes unis"},
    {value: "53", label: "Équateur"},
    {value: "54", label: "Érythrée"},
    {value: "55", label: "Espagne"},
    {value: "56", label: "Estonie"},
    {value: "57", label: "Etats-Unis"},
    {value: "58", label: "Éthiopie"},
    {value: "187", label: "Ex-Yougoslavie"},
    {value: "59", label: "Fidji"},
    {value: "60", label: "Finlande"},
    {value: "61", label: "France"},
    {value: "62", label: "Gabon"},
    {value: "63", label: "Gambie"},
    {value: "64", label: "Géorgie"},
    {value: "65", label: "Ghana"},
    {value: "66", label: "Grèce"},
    {value: "67", label: "Grenade"},
    {value: "68", label: "Guatemala"},
    {value: "69", label: "Guinée"},
    {value: "71", label: "Guinée équatoriale"},
    {value: "70", label: "Guinée-Bissao"},
    {value: "72", label: "Guyana"},
    {value: "73", label: "Haïti"},
    {value: "74", label: "Honduras"},
    {value: "75", label: "Hongrie"},
    {value: "76", label: "Inde"},
    {value: "77", label: "Indonésie"},
    {value: "78", label: "Iran"},
    {value: "79", label: "Iraq"},
    {value: "80", label: "Irlande"},
    {value: "81", label: "Islande"},
    {value: "82", label: "Israël"},
    {value: "83", label: "Italie"},
    {value: "84", label: "Jamaïque"},
    {value: "85", label: "Japon"},
    {value: "86", label: "Jordanie"},
    {value: "87", label: "Kazakhstan"},
    {value: "88", label: "Kenya"},
    {value: "89", label: "Kirghizistan"},
    {value: "90", label: "Kiribati"},
    {value: "223", label: "Kosovo"},
    {value: "91", label: "Koweït"},
    {value: "92", label: "Laos"},
    {value: "93", label: "Lesotho"},
    {value: "94", label: "Lettonie"},
    {value: "95", label: "Liban"},
    {value: "96", label: "Liberia"},
    {value: "97", label: "Libye"},
    {value: "98", label: "Liechtenstein"},
    {value: "99", label: "Lituanie"},
    {value: "100", label: "Luxembourg"},
    {value: "101", label: "Macédoine"},
    {value: "102", label: "Madagascar"},
    {value: "103", label: "Malaisie"},
    {value: "104", label: "Malawi"},
    {value: "105", label: "Maldives"},
    {value: "106", label: "Mali"},
    {value: "107", label: "Malte"},
    {value: "108", label: "Maroc"},
    {value: "109", label: "Marshall (les Îles)"},
    {value: "110", label: "Maurice"},
    {value: "111", label: "Mauritanie"},
    {value: "112", label: "Mexique"},
    {value: "113", label: "Micronésie"},
    {value: "114", label: "Moldavie"},
    {value: "115", label: "Monaco"},
    {value: "116", label: "Mongolie"},
    {value: "224", label: "Monténégro"},
    {value: "117", label: "Mozambique"},
    {value: "118", label: "Namibie"},
    {value: "119", label: "Nauru"},
    {value: "120", label: "Népal"},
    {value: "121", label: "Nicaragua"},
    {value: "122", label: "Niger"},
    {value: "123", label: "Nigeria"},
    {value: "124", label: "Niue"},
    {value: "125", label: "Norvège"},
    {value: "126", label: "Nouvelle-Zélande"},
    {value: "127", label: "Oman"},
    {value: "128", label: "Ouganda"},
    {value: "129", label: "Ouzbékistan"},
    {value: "130", label: "Pakistan"},
    {value: "131", label: "Panama"},
    {value: "132", label: "Papouasie - Nouvelle Guinée"},
    {value: "133", label: "Paraguay"},
    {value: "134", label: "Pays-Bas"},
    {value: "135", label: "Pérou"},
    {value: "136", label: "Philippines"},
    {value: "137", label: "Pologne"},
    {value: "138", label: "Portugal"},
    {value: "139", label: "Qatar"},
    {value: "140", label: "République centrafricaine"},
    {value: "188", label: "République démocratique du Congo"},
    {value: "141", label: "République dominicaine"},
    {value: "142", label: "République tchèque"},
    {value: "143", label: "Roumanie"},
    {value: "144", label: "Royaume-Uni"},
    {value: "145", label: "Russie"},
    {value: "146", label: "Rwanda"},
    {value: "147", label: "Saint-Christophe-et-Niévès"},
    {value: "148", label: "Sainte-Lucie"},
    {value: "149", label: "Saint-Marin"},
    {value: "151", label: "Saint-Vincent-et-les Grenadines"},
    {value: "152", label: "Salomon"},
    {value: "153", label: "Salvador"},
    {value: "154", label: "Samoa"},
    {value: "155", label: "Sao Tomé-et-Principe"},
    {value: "156", label: "Sénégal"},
    {value: "225", label: "Serbie"},
    {value: "157", label: "Seychelles"},
    {value: "158", label: "Sierra Leone"},
    {value: "159", label: "Singapour"},
    {value: "160", label: "Slovaquie"},
    {value: "161", label: "Slovénie"},
    {value: "162", label: "Somalie"},
    {value: "163", label: "Soudan"},
    {value: "164", label: "Sri Lanka"},
    {value: "165", label: "Suède"},
    {value: "166", label: "Suisse"},
    {value: "167", label: "Suriname"},
    {value: "168", label: "Swaziland"},
    {value: "169", label: "Syrie"},
    {value: "170", label: "Tadjikistan"},
    {value: "171", label: "Tanzanie"},
    {value: "172", label: "Tchad"},
    {value: "173", label: "Thaïlande"},
    {value: "174", label: "Togo"},
    {value: "175", label: "Tonga"},
    {value: "176", label: "Trinité-et-Tobago"},
    {value: "177", label: "Tunisie"},
    {value: "178", label: "Turkménistan"},
    {value: "179", label: "Turquie"},
    {value: "180", label: "Tuvalu"},
    {value: "181", label: "Ukraine"},
    {value: "182", label: "Uruguay"},
    {value: "183", label: "Vanuatu"},
    {value: "150", label: "Vatican"},
    {value: "184", label: "Venezuela"},
    {value: "185", label: "Viêt Nam"},
    {value: "186", label: "Yémen"},
    {value: "189", label: "Zambie"},
    {value: "190", label: "Zimbabwe"},
]*/

export const situation = [
    {value: "celibataire", label: "Célibataire"},
    {value: "marie", label: "Marié"},
    {value: "divorce", label: "Divorcé"},
    {value: "veuf", label: "Veuf"},
    {value: "pacs", label: "Pacsé"},
    {value: "concubin", label: "Concubin"},
    {value: "separe", label: "Séparé"},
    {value: "autre", label: "Autre"},
]   

export const civilite = [
    {value: "0", label: "M."},
    {value: "1", label: "Mme"},
]   
/*
export const profession = [
    {value: "salarie_non_sedentaire", label: "Salarié non sédentaire"},
    {value: "salarie_sedentaire", label: "Salarié sédentaire"},
    {value: "agriculteur", label: "Agriculteur"},
    {value: "artisan", label: "Artisan"},
    {value: "commercant_sedentaire", label: "Commerçant sédentaire"},
    {value: "commercant_non_sedentaire", label: "Commerçant non sédentaire"},
    {value: "chef_entreprise", label: "Chef d'entreprise"},
    {value: "cadre", label: "Cadre"},
    {value: "profession_liberale", label: "Profession libérale"},
    {value: "employe", label: "Employé"},
    {value: "ouvrier", label: "Ouvrier"},
    {value: "ecclesiastique", label: "Ecclesiastique"},
    {value: "etudiant", label: "Étudiant"},
    {value: "recherche_emploi", label: "Recherche d'emploi"},   
    {value: "retraite", label: "Retraité"},
    {value: "sans_emploi", label: "Sans emploi"},
    {value: "autre", label: "Autre"},
]*/

export const EU_COUNTRIES = [
    "61", // France
    "195", // Allemagne
    "203", // Autriche
    "210", // Belgique
    "221", // Bulgarie
    "36", // Chypre
    "46", // Croatie
    "48", // Danemark
    "55", // Espagne
    "56", // Estonie
    "60", // Finlande
    "66", // Grèce
    "75", // Hongrie
    "80", // Irlande
    "83", // Italie
    "94", // Lettonie
    "99", // Lituanie
    "100", // Luxembourg
    "101", // Macédoine
    "107", // Malte
    "142", // République tchèque
    "144", // Royaume-Uni
    "137", // Pologne
    "138", // Portugal
    "143", // Roumanie
    "161", // Slovénie
    "160", // Slovaquie
    "165", // Suède
    "166", // Suisse
  ];