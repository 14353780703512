import React from "react";

export default function DrawerTop(props) {
  console.log(props);
  return (
    <div className="h-1/4 w-full flex flex-col">
      <div className="text-promo-green text-3xl font-semibold"></div>
    </div>
  );
}
